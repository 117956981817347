import React, { useState } from 'react';
import GoogleForm from '../GoogleForm';
import './getConsultation.css';

const GetConsultation = () => {
  const [inputs, setInputs] = useState({
    name: { id: 2005620554, value: '' },
    email: { id: 1045781291, value: '' }
  });

  const handleChange = e => {
    const { value, name } = e.target;
    setInputs({ ...inputs, [name]: { ...inputs[name], value } });
  };

  const clearInputs = () => {
    setInputs({
      name: { id: 2005620554, value: '' },
      email: { id: 1045781291, value: '' }
    });
  };

  return (
    <section className="get-consultation-section">
      <h2>Get a consultation</h2>
      <GoogleForm
        inputs={inputs}
        clearInputs={clearInputs}
        className="consultation-form"
      >
        <label>
          your name
          <input
            type="text"
            name="name"
            required
            value={inputs.name.value}
            onChange={handleChange}
          />
        </label>
        <label>
          your email
          <input
            type="email"
            name="email"
            required
            value={inputs.email.value}
            onChange={handleChange}
          />
        </label>
        <button type="submit">message</button>
      </GoogleForm>
    </section>
  );
};

export default GetConsultation;
