import React, { useState } from 'react';
import { useTitle } from '../hooks';
import Banner from '../components/Banner';
import GoogleForm from '../components/GoogleForm';

var bnrimg = require('../images/banner/about.webp');

const ContactUs = () => {
  const [inputs, setInputs] = useState({
    name: { id: 2005620554, value: '' },
    email: { id: 1045781291, value: '' },
    message: { id: 839337160, value: '' }
  });

  const handleChange = e => {
    const { value, name } = e.target;
    setInputs({ ...inputs, [name]: { ...inputs[name], value } });
  };

  const clearInputs = () => {
    setInputs({
      name: { id: 2005620554, value: '' },
      email: { id: 1045781291, value: '' },
      message: { id: 839337160, value: '' }
    });
  };
  useTitle('Contact Us');

  return (
    <>
      <div className="page-content">
        <Banner
          title="Inspired design for people"
          pagename="Contact us"
          bgimage={bnrimg}
        />
        {/* SECTION CONTENTG START */}
        <div className="section-full p-tb80 tm-shortcode-wrap">
          {/* LOCATION BLOCK*/}
          <div className="container">
            {/* TITLE START */}
            <div className="section-head text-left text-black">
              <h2 className="text-uppercase font-36">Where to Find us </h2>
              <div className="wt-separator-outer">
                <div className="wt-separator bg-black" />
              </div>
            </div>
            {/* TITLE END */}
            {/* GOOGLE MAP & CONTACT FORM */}
            <div className="section-content">
              {/* CONTACT FORM*/}
              <div className="wt-box">
                <GoogleForm
                  inputs={inputs}
                  clearInputs={clearInputs}
                  className="contact-form cons-contact-form"
                >
                  <div className="contact-one p-a40 p-r150">
                    <div className="contact-info bg-black text-white p-a30">
                      <div className="wt-icon-box-wraper left p-b30">
                        <div className="icon-sm">
                          <i className="iconmoon-smartphone-1" />
                        </div>
                        <div className="icon-content text-white ">
                          <h5 className="m-t0 text-uppercase">Phone number</h5>
                          <a
                            href="https://wa.me/+201000922996"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            01000922996
                          </a>
                          <a href="tel:01002082455">01002082455</a>
                        </div>
                      </div>
                      <div className="wt-icon-box-wraper left p-b30">
                        <div className="icon-sm">
                          <i className="iconmoon-email" />
                        </div>
                        <div className="icon-content text-white">
                          <h5 className="m-t0  text-uppercase">
                            Email address
                          </h5>
                          <a href="mailto:info@archrete.com">
                            Info@Archrete.Com
                          </a>
                        </div>
                      </div>
                      <div className="wt-icon-box-wraper left">
                        <div className="icon-sm">
                          <i className="iconmoon-travel" />
                        </div>
                        <div className="icon-content text-white">
                          <h5 className="m-t0  text-uppercase">Address info</h5>
                          <a
                            href="https://goo.gl/maps/7zWwC1W7FzuGiZH1A"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            123 Yasmeen 7th, Fifth settlement, Egypt.
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <input
                        name="name"
                        type="text"
                        required
                        className="form-control"
                        placeholder="Name"
                        value={inputs.name.value}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        name="email"
                        type="email"
                        className="form-control"
                        required
                        placeholder="Email"
                        value={inputs.email.value}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <textarea
                        name="message"
                        rows={3}
                        className="form-control "
                        required
                        placeholder="Message"
                        value={inputs.message.value}
                        onChange={handleChange}
                      />
                    </div>
                    <button
                      name="submit"
                      type="submit"
                      value="Submit"
                      className="site-button black radius-no text-uppercase"
                    >
                      <span className="font-12 letter-spacing-5">Submit</span>
                    </button>
                  </div>
                </GoogleForm>
              </div>
            </div>
          </div>
        </div>
        <div className="section-full">
          <div className="gmap-outline">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13816.572971863665!2d31.4568767!3d30.0327481!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1458237ac7874a15%3A0xdb9919bab60a2bbe!2sARCHRETE!5e0!3m2!1sen!2seg!4v1676909047127!5m2!1sen!2seg"
              width="100%"
              height="480"
              title="archrete"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
        {/* SECTION CONTENT END */}
      </div>
    </>
  );
};

export default ContactUs;
