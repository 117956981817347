import React, { useRef } from 'react';
import { useInView } from '../../hooks';
import { Link } from 'react-router-dom';

import './projectCard.css';

const ProjectCard = ({ name, slug, imgs }) => {
  const card = useRef(null);
  const inView = useInView(card);

  const fw = name.split(' ')[0];
  const ron = name.substring(name.indexOf(' '));

  return (
    <div className={`project-card ${inView ? 'in-view' : ''}`}>
      <div ref={card} className="card-bg-img">
        <img src={imgs[0]} alt=" background tower" />
      </div>
      <div className="card-img">
        <img src={imgs[1]} alt="tower" />
      </div>
      <div className="card-overlay">
        <Link to={`/projects/${slug}`}>
          <span className="first-name bold">{fw}</span>
          {ron}
        </Link>
      </div>
    </div>
  );
};

export default ProjectCard;
