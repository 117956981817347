import React from 'react';
import { Link } from 'react-router-dom';

const Banner = ({ bgimage, pagename, title }) => {
  return (
    <>
      <div
        className="wt-bnr-inr overlay-wraper bg-parallax bg-top-center"
        data-stellar-background-ratio="0.5"
        style={{ backgroundImage: `url('${bgimage}')` }}
      >
        <div className="overlay-main bg-black opacity-03" />
        <div className="container">
          <div className="wt-bnr-inr-entry">
            {title ? (
              <div className="banner-title-outer">
                <div className="banner-title-name">
                  <h2 className="text-white text-uppercase letter-spacing-5 font-18 font-weight-300">
                    {title}
                  </h2>
                </div>
              </div>
            ) : (
              ''
            )}
            {/* BREADCRUMB ROW */}
            {pagename ? (
              <div className="p-tb20">
                <div>
                  <ul className="wt-breadcrumb breadcrumb-style-2">
                    <li>
                      <Link to={'/'}>Home</Link>
                    </li>
                    <li>{pagename}</li>
                  </ul>
                </div>
              </div>
            ) : (
              ''
            )}
            {/* BREADCRUMB ROW END */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
