import React from 'react';
import { Link } from 'react-router-dom';

import './ourProjects.css';
import {
  tower1,
  tower1Enlarged,
  twinTower1,
  twinTower2,
  evoraTower1,
  evoraTower2,
  mMall1,
  mMall2
} from '../../assets';
import ProjectCard from '../ProjectCard/ProjectCard';

const OurProjects = () => {
  return (
    <section className="our-projects">
      <h2>
        Our <span className="bold gold">Projects</span>
      </h2>
      <div className="projects-container">
        <ProjectCard
          slug="3"
          imgs={[tower1, tower1Enlarged]}
          name="east tower"
        />
        <ProjectCard
          slug="9"
          imgs={[twinTower1, twinTower2]}
          name="twin tower"
        />
        <ProjectCard
          slug="25"
          imgs={[evoraTower1, evoraTower2]}
          name="evora tower"
        />
        <ProjectCard slug="18" imgs={[mMall1, mMall2]} name="m tower" />
      </div>
      <Link to="/projects" className="link-btn">
        view all projects
      </Link>
    </section>
  );
};

export default OurProjects;
