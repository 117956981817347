import React from 'react';

var img1 = require('../images/projects/evora/rovan shot 03.webp');
var img2 = require('../images/projects/Trave tower/trave mall shot 4.webp');
var img3 = require('../images/projects/31 North/5.webp');
var img4 = require('../images/projects/Suli compound/UC Compound Golf Still 02.RGB_color.0000.webp');

class Story extends React.Component {
  render() {
    return (
      <>
        <div className="section-full bg-gray p-tb90 square_shape1 tw-our-story-wrap">
          <div className="container">
            {/* TITLE START */}
            <div className="section-head text-left text-black">
              <h2 className="text-uppercase font-36">Our Story</h2>
              <div className="wt-separator-outer">
                <div className="wt-separator bg-black" />
              </div>
            </div>
            {/* TITLE END */}
            <div className="section-content our-story">
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="wt-media our-story-pic">
                    <img src={img1} alt="" />
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="wt-box our-story-detail">
                    <h2 className="text-uppercase gold">EVORA MALL</h2>
                    <p>
                      In the design, we relied on modern designs in a new and
                      different way that helps achieve the highest proportion of
                      construction in a modern way. And, because the location is
                      the most important factor of drought and the aesthetic
                      aspect constitutes a drought factor surrounding the
                      project ,We used changing and modern materials in the
                      facade that reflect different geometric lines.{' '}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="wt-box our-story-detail">
                    <h2 className="text-uppercase gold">Trave tower</h2>
                    <p>
                      The TRAVE project is located In the heart of the downtown
                      area in the administrative capital In the Central Park
                      area The neighborhood of the main street, the width of 70
                      meters, linking the northern axis of Zayed, between the
                      government district and the district of Al-Maqat{' '}
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="wt-media our-story-pic">
                    <img src={img2} alt="" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="wt-media our-story-pic">
                    <img src={img3} alt="" />
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="wt-box our-story-detail">
                    <h2 className="text-uppercase gold">31 NORTH TOWER</h2>
                    <p>
                      31North is the First Festival tower At the New Capital
                      located on an area of more than 18,000 square meters with
                      a main facade of 100 meters in the touristic towers area
                      in "Downtown" in the New Administrative Capital, which is
                      a distinctive area with its view of the Green River and a
                      number of important projects, and the tower consists of 36
                      floors with a height of 131 Meters, and it includes
                      commercial units (shops, restaurants and cafes),
                      administrative offices, medical clinics, and hotel
                      apartments, in addition to many facilities for customer
                      service and tower visitors, comparable to those of the
                      most famous similar towers regionally and internationally.{' '}
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="wt-box our-story-detail">
                    <h2 className="text-uppercase gold">Suli Compound</h2>
                    <p>
                      a new iconic project to rise the scale of creativity and
                      diversity to announce.{' '}
                    </p>
                    <p>
                      The first compound with an enormous golf area in the
                      Administrative Capital.{' '}
                    </p>
                    <p>
                      A privileged location on two main streets and on the inner
                      ring of the R8 with many Amenities and Features.{' '}
                    </p>
                    <p>
                      the compound overlooks the landscapes in a special place
                      in the heart of the capital city. All units in the
                      compound overlook glass frontispiece, as it has been
                      ensured that the compound has international and modern
                      facades.{' '}
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="wt-media our-story-pic">
                    <img src={img4} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Story;
