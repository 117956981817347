import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useInView } from '../../hooks';
import './countAnimation.css';

const CountAnimation = ({ start = 0, end = 0, duration = 0, caption = '' }) => {
  const [count, setCount] = useState(start);
  const counter = useRef(null);
  const loaded = useRef(false);
  const inView = useInView(counter);

  const animateValue = useCallback(() => {
    let startTimestamp = null;
    const step = timestamp => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      setCount(Math.floor(progress * (end - count) + count));
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  }, [count, duration, end]);

  useEffect(() => {
    if (!loaded.current && inView) {
      animateValue();
      loaded.current = true;
    }
  }, [count, inView, animateValue]);

  return (
    <div
      className={`number-container ${inView ? 'number-inview' : ''}`}
      ref={counter}
    >
      <p className="number bold gold">+{count}</p>
      <p className="number-caption">{caption}</p>
    </div>
  );
};

export default CountAnimation;
