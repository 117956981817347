import React, { useRef } from 'react';
import { useInView } from '../../hooks';
import { Link } from 'react-router-dom';
import './suliCompound.css';

const SuliCompound = () => {
  const titleElement = useRef();
  const titleInView = useInView(titleElement);
  return (
    <section className="suli-compound-section">
      <iframe
        src="//storage.net-fs.com/hosting/7688127/0/"
        name="UC Compound"
        width="100%"
        height="100%"
        title="Suli compound"
        frameborder="0"
        allow="fullscreen; accelerometer; gyroscope; magnetometer; vr; xr; xr-spatial-tracking; camera; microphone"
        allowfullscreen="true"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        oallowfullscreen="true"
        msallowfullscreen="true"
      ></iframe>

      <div className={`suli-info-container ${titleInView ? 'inView' : ''}`}>
        <Link ref={titleElement} className="title-link" to="/projects/39">
          <h2>
            <span className="gold bold">Suli</span>
            <br />
            Compound
          </h2>
        </Link>
        <Link to="/projects/39" className="link-btn">
          Explore Project
        </Link>
      </div>
    </section>
  );
};

export default SuliCompound;
