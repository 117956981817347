import React from 'react';
import './ourMap.css';

import ImageMagnifier from '../ImageMagnifier/ImageMagnifier';
import { ourMap, ourMapMobile } from '../../assets';

const OurMap = () => {
  return (
    <section className="our-map-section">
      <h2>our map</h2>
      <h3>
        where to find <span className="gold">our creations</span>
      </h3>
      <div className="map-container">
        <ImageMagnifier srcSet={ourMapMobile} src={ourMap} alt="our map" />
      </div>
    </section>
  );
};

export default OurMap;
