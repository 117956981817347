import React from 'react';
import { useTitle } from '../../hooks';
import './imaket.css';
import { iMaketVideo } from '../../assets';
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer';
import Slider3 from '../../components/Slider/Slider3';

const video1 = require('../../assets/imaket-features/1-7.webm');
const video2 = require('../../assets/imaket-features/2.webm');
const video3 = require('../../assets/imaket-features/3.webm');
const video4 = require('../../assets/imaket-features/4.webm');
const video5 = require('../../assets/imaket-features/5.webm');
const video6 = require('../../assets/imaket-features/6.webm');
const video7 = require('../../assets/imaket-features/1-7.webm');
const video8 = require('../../assets/imaket-features/8.webm');
const video9 = require('../../assets/imaket-features/9.webm');
const video10 = require('../../assets/imaket-features/10.webm');
const video11 = require('../../assets/imaket-features/11.webm');
const video12 = require('../../assets/imaket-features/12.webm');

const IMaket = () => {
  useTitle('I Maket');
  return (
    <div className="page-content">
      <VideoPlayer
        className="imaket-page-video"
        source={iMaketVideo}
        muted
        autoPlay
        loop
        allowFullScreen
      />
      <section className="imaket-page-description">
        <h1>
          Reality in a <span className="bold gold">new modern way</span>
        </h1>
        <p>
          GREAT TOOL TO PRESENT ARCHITECTURAL PROJECTS RUN EYE-CATCHING
          PRESENTATIONS OF ARCHITECTURAL PROJECTS IMAKET IS AN ELEGANT SOLUTION
          TO SHOWCASE YOUR DEVELOPMENT IN A STUNNING LIGHT - SPICE UP THE 3D
          INTERACTIVE MODEL WITH AS MANY DETAILS AS YOU WISH. TO CREATE A FULL
          IMMERSION FOR THE SPECTATOR.
        </p>
      </section>
      <Slider3 />
      <div className="imaket-features section-full p-tb90 square_shape1 tm-news-wrap">
        <div className="container">
          <div className="section-head text-left text-black">
            <h2 className="text-uppercase font-36">Features</h2>
            <div className="wt-separator-outer">
              <div className="wt-separator bg-black" />
            </div>
          </div>
          <div className="news-listing ">
            <div className="imaket-page-description">
              <h2>
                Great tool to present{' '}
                <span className="bold gold">architectural projects</span>
              </h2>
              <p>
                i-Mquette runs an eye-catching 3D interactive architectural
                presentation. It’s an elegant solution to showcase your projects
                in a stunning light. Stimulate your 3D interactive model with
                many details to create a full immersion for the spectator and
                advertise commercial or residential real estate property with
                touchscreen.
              </p>
            </div>

            <div className="imaket-page-description">
              <h2>
                Eye-catching photo real{' '}
                <span className="bold gold">3D renders</span>
              </h2>
              <p>
                Introduce the project no matter the scale ranging from small
                scale constructions to massive residential quarter. Showcasing
                already built environment context for your project with top tier
                amenities, landmarks and transportation.
              </p>
              <VideoPlayer source={video1} allowFullScreen autoPlay muted />
            </div>

            <div className="imaket-page-description">
              <h2>
                Unit <span className="bold gold">search</span>
              </h2>
              <p>
                The Interactive 3D model retrieves all the information of the
                spaces from area, availability and current price. Showcase your
                plans in typical 3D sections and explore the real-estate project
                spaces from either condos or commercial spaces and compare the
                layouts, views and orientation to decide on the most suitable
                option.
              </p>
              <VideoPlayer source={video2} allowFullScreen autoPlay muted />
            </div>

            <div className="imaket-page-description">
              <h2>
                Floor <span className="bold gold">plans</span>
              </h2>
              <p>
                Showcase your plans in typical 2D plans with dimensions,
                Comprised of spaces that you laid your eyes on for further
                comparison and easier decision making.
              </p>
              <VideoPlayer source={video3} allowFullScreen autoPlay muted />
            </div>

            <div className="imaket-page-description">
              <h2>
                One-click <span className="bold gold">camera views</span>
              </h2>
              <p>
                Showcase your plans in typical 2D plans with dimensions,
                Comprised of spaces that you laid your eyes on for further
                comparison and easier decision making.
              </p>
              <VideoPlayer source={video4} allowFullScreen autoPlay muted />
              <VideoPlayer source={video5} allowFullScreen autoPlay muted />
            </div>

            <div className="imaket-page-description">
              <h2>
                On-Site amenities and{' '}
                <span className="bold gold">surroundings</span>
              </h2>
              <p>
                Wondering what is your future investment on-site facilities has
                to offer? Parks, playgrounds, gyms, schools, offices…..etc .
                Look around and examine all the amenities tap on them to receive
                a quick overview accompanied by a realistic 3D rendering.
              </p>
              <p>
                The surrounding is the key point to consider when deciding
                between multiple similar projects. Explore the neighborhood and
                find the distance between objects around you for better space
                understanding.
              </p>
              <VideoPlayer source={video6} allowFullScreen autoPlay muted />
              <VideoPlayer source={video7} allowFullScreen autoPlay muted />
            </div>

            <div className="imaket-page-description">
              <h2>
                Day/Night <span className="bold gold">weather slider</span>
              </h2>
              <p>
                View your project in different settings.Drag your finger on the
                24 hours slider, reposition the sun in its natural course or see
                how it will look in rainy weather, choose the most suitable
                option for you- be it in the nighttime or peaceful family
                territory. The mood is only for you to choose.
              </p>
              <VideoPlayer source={video8} allowFullScreen autoPlay muted />
              <VideoPlayer source={video9} allowFullScreen autoPlay muted />
            </div>

            <div className="imaket-page-description">
              <h2>
                Tour <span className="bold gold">mode</span>
              </h2>
              <p>
                Explore your city from different perspectives using tour mode
                you could overview your city either from the sky with an
                airplane, land using trains or sea using boats.
              </p>
              <VideoPlayer source={video10} allowFullScreen autoPlay muted />
            </div>

            <div className="imaket-page-description">
              <h2>
                Exterior/Interior <span className="bold gold">gallery</span>
              </h2>
              <p>
                Tap into the future and check out how your unbuilt space will
                look like right away. The gallery contains not only
                interior/exterior realistic 3D renders with multiple options but
                also space where you can find immersive 360’s and panoramas.
              </p>
              <VideoPlayer source={video11} allowFullScreen autoPlay muted />
            </div>

            <div className="imaket-page-description">
              <h2>
                Interactive interior{' '}
                <span className="bold gold">application</span>
              </h2>
              <p>
                Realistic 3D Interactive walkthrough experience with full
                detailed design.
              </p>
              <VideoPlayer source={video12} allowFullScreen autoPlay muted />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IMaket;
