import React from 'react';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const images = [
  require('../assets/Evora 1.png'),
  require('../assets/M Mall 1.png'),
  require('../assets/Twin Tower 1.png'),
  require('../assets/East Tower 1.png')
];

class About extends React.Component {
  render() {
    const options = {
      loop: true,
      autoplay: true,
      autoplayTimeout: 3000,
      margin: 30,
      nav: true,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>'
      ],
      items: 1,
      dots: false
    };
    return (
      <>
        <div className="section-full p-tb90 bg-gray square_shape2 tm-about2-wrap">
          <div className="container">
            <div className="section-content ">
              <div className="row">
                <div className="col-lg-5 col-md-6">
                  <div className="m-about m-l50 m-r50">
                    <OwlCarousel
                      className="owl-carousel about-us-carousel owl-btn-bottom-right"
                      {...options}
                    >
                      {images.map((item, index) => (
                        <div className="item" key={index}>
                          <div className="ow-img wt-img-effect zoom-slow">
                            <Link to={''}>
                              <img src={item} alt="" />
                            </Link>
                          </div>
                        </div>
                      ))}
                    </OwlCarousel>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 ">
                  <div className="m-about-containt text-uppercase text-black p-t30">
                    <span className="font-30 font-weight-300">About Us</span>
                    <h2 className="font-40 gold">
                      Our mission is to create the best engineering designs and
                      provide the highest quality with the lowest cost.
                    </h2>
                    <p>
                      <b>
                        Our Vision is to create a new path of creativity in the
                        field of design and show the worthy pursuit of beauty
                        and truth in reality
                      </b>
                    </p>
                    <p className="text-capitalized">
                      Archrete is a Design Firm based in Cairo Founded in 2017
                      by Arch.Mohamed Tammam, Eng.Moustafa Afify, and
                      Eng.Mohammed Samir. Each of us specializes in different
                      fields to cover all aspects of engineering consultancy
                      services.
                    </p>
                    <p className="text-capitalized">
                      We have successfully established more than 1000+ projects
                      all over egypt, more than 60+ projects in the new capital
                      we manage this process in a very short time, proving that
                      archrete is the best place to complete the project's life
                      cycle in egypt. we make every piece a masterpiece in our
                      designs to become one of egypt's landmarks in our new
                      republic.
                    </p>
                    <p className="text-capitalized">
                      The designs varied between commercial and administrative
                      malls , residential compounds and mega projects. All of
                      this was a result of our passion and experience in the
                      market's fierce competition till Archrete was able to earn
                      its position among all.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default About;
