import React from 'react';

const GoogleForm = ({
  formID = '1FAIpQLSetaQMDjKAFphKdXzPBR2KeHuWGi2CJjYk9s73xAQ8Hw5Q3_A',
  clearInputs,
  inputs,
  ...props
}) => {
  const formUrl = `https://docs.google.com/forms/d/e/${formID}/formResponse`;

  const doSubmit = async e => {
    e.preventDefault();
    const formData = new FormData();
    Object.keys(inputs).forEach(item => {
      formData.append(`entry.${inputs[item].id}`, inputs[item].value);
    });

    await fetch(formUrl, {
      method: 'POST',
      mode: 'no-cors',
      body: formData
    })
      .then(() => {
        e.target.classList.remove('failed');
        e.target.classList.add('sent');
        clearInputs();
      })
      .catch(() => {
        e.target.classList.remove('sent');
        e.target.classList.add('failed');
      });
  };

  return (
    <form {...props} onSubmit={doSubmit}>
      {props.children}
    </form>
  );
};

export default GoogleForm;
