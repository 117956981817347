import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { appLogo } from '../../assets';

import './header.css';

const Header = () => {
  const [showSideBar, setShowSideBar] = useState(false);
  return (
    <header>
      <nav>
        <NavLink to="/">
          <img className="logo" src={appLogo} alt="site-logo" />
        </NavLink>
        <div className={`nav-links ${showSideBar ? 'show-sidebar' : ''}`}>
          {showSideBar ? (
            <div
              className="sidebar-overlay"
              onClick={() => setShowSideBar(false)}
            ></div>
          ) : (
            ''
          )}
          <button
            className="hamburger-btn"
            onClick={() => setShowSideBar(!showSideBar)}
          >
            <hr />
            <hr />
            <hr />
          </button>
          <ul>
            <li>
              <NavLink to="/about">About</NavLink>
            </li>
            <li>
              <NavLink to="/projects">Projects</NavLink>
            </li>
            <li>
              <NavLink to="/imaket">I Maket</NavLink>
            </li>
            <li>
              <NavLink to="/career">Career</NavLink>
            </li>
            <li>
              <NavLink className="link-btn" to="/contact-us">
                Contacts
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;
