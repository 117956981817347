import React from 'react';
import { useTitle } from '../hooks/';
import Banner from '../components/Banner';
import About from '../components/About';
import TeamMembers2 from '../components/TeamMembers2';
import Services from '../components/Services';
import Story from '../components/Story';

var bnrimg = require('../images/banner/about.webp');

const AboutUs = () => {
  useTitle('About Us');
  return (
    <>
      <div className="page-content">
        <Banner
          title="Fusing logic with imagination and truth with discovery."
          pagename="About Us"
          bgimage={bnrimg}
        />
        <About />
        <TeamMembers2 />
        <Services />
        <Story />
      </div>
    </>
  );
};

export default AboutUs;
