import React from 'react';
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { getRelatedProjects } from '../utils/api';

const RelatedProjects = ({ project }) => {
  const projects = getRelatedProjects(project);

  const options = {
    loop: false,
    margin: 30,
    nav: true,
    dots: false,
    navText: [
      '<i class="fa fa-angle-left"></i>',
      '<i class="fa fa-angle-right"></i>'
    ],
    responsive: {
      0: {
        items: 1
      },
      660: {
        items: 2
      },
      767: {
        items: 3
      },
      1000: {
        items: 3
      }
    }
  };
  return (
    <>
      {projects.length ? (
        <div className="section-content">
          {/* TITLE START */}
          <div className="text-left">
            <h2 className="text-uppercase font-36">Related projects</h2>
            <div className="wt-separator-outer">
              <div className="wt-separator bg-black" />
            </div>
          </div>
          {/* TITLE END */}
          {/* CAROUSEL */}
          <div className="section-content">
            <OwlCarousel
              className="owl-carousel blog-related-slider  owl-btn-top-right "
              {...options}
            >
              {projects.map((item, index) => (
                <div className="item" key={`rlp${index}`}>
                  <div className="blog-post blog-grid date-style-1">
                    <div className="wt-post-media wt-img-effect zoom-slow">
                      <Link to={`/projects/${item.id}`}>
                        <img src={item.images.thumb} alt="" />
                      </Link>
                    </div>
                    <div className="wt-post-info p-a20 bg-gray text-black">
                      <div className="wt-post-title ">
                        <h2 className="post-title">
                          <Link
                            to={`/projects/${item.id}`}
                            className="text-black font-16 letter-spacing-2 font-weight-600"
                          >
                            {item.title}
                          </Link>
                        </h2>
                      </div>
                      <div className="wt-post-text">
                        <p>{item.description.par1.substring(0, 255)}...</p>
                      </div>
                      <Link
                        to={`/projects/${item.id}`}
                        className="site-button black radius-no text-uppercase"
                      >
                        <span className="font-12 letter-spacing-5">
                          {' '}
                          Read More{' '}
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default RelatedProjects;
