import React from 'react';
import { Link } from 'react-router-dom';
import './footer.css';

import {
  footerLogo,
  emailIcon,
  phoneIcon,
  locationIcon,
  ytIcon,
  fbIcon,
  whatsappIcon,
  instaIcon
} from '../../assets';

const Footer = () => {
  return (
    <footer>
      <section className="upper-footer">
        <div className="footer-logo">
          <img src={footerLogo} alt="logo" />
        </div>
        <section className="footer-contact-info">
          <h2>Contact</h2>
          <ul>
            <li>
              <img src={locationIcon} alt="location" />
              <a
                href="https://goo.gl/maps/7zWwC1W7FzuGiZH1A"
                target="_blank"
                rel="noopener noreferrer"
              >
                123 Yasmeen 7th, Fifth settlement, Egypt.
              </a>
            </li>
            <li>
              <img src={emailIcon} alt="email" />
              <a href="mailto:info@archrete.com" target="_self">
                info@archrete.com
              </a>
            </li>
            <li>
              <img src={phoneIcon} alt="phone" />
              <a href="tel:01002082455" target="_self">
                +2 01002082455
              </a>
            </li>
            <li>
              <img src={whatsappIcon} alt="phone" />
              <a
                href="https://wa.me/+201000922996"
                target="_blank"
                rel="noopener noreferrer"
              >
                +2 01000922996
              </a>
            </li>
          </ul>
        </section>
        <section>
          <h2>Our services</h2>
          <ul>
            <li>
              <Link to="/about">about</Link>
            </li>
            <li>
              <Link to="/projects">projects</Link>
            </li>
            <li>
              <Link to="/imaket">i maket</Link>
            </li>
            <li>
              <Link to="/career">career</Link>
            </li>
            <li>
              <Link to="/contact-us">contact us</Link>
            </li>
          </ul>
        </section>
      </section>
      <div className="auther-info">
        <p className="footer-rights">
          All rights reserved. Privacy Policy | 2022
        </p>
        <div className="social-links">
          Follow Us
          <a
            href="https://instagram.com/archrete.ec"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={instaIcon} alt="instagram" />
          </a>
          <a
            href="https://www.facebook.com/Archrete/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={fbIcon} alt="facebook" />
          </a>
          <a
            href="https://www.youtube.com/channel/UClfZwqOwTa_6kLtoBtLyCZg"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={ytIcon} alt="youtube" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
