import React, { useState } from 'react';
import './heroSection.css';
import { heroBG1080, heroBG720, cloud, cloud1 } from '../../assets';

const HeroSection = () => {
  const [bgLoaded, setBgLoaded] = useState(false);
  return (
    <section className="hero-section">
      <div className="bg-container">
        <picture onLoad={() => setBgLoaded(true)}>
          <source
            srcSet={heroBG1080}
            media="(min-width: 720px)"
            loading="eager"
          />
          <img src={heroBG720} alt="high towers" loading="eager" />
        </picture>
      </div>
      <div className="slogan">
        <h1>
          New <i>Heights</i> In Design
        </h1>
      </div>
      <div className={`cloud-container ${bgLoaded ? 'bg-loaded' : ''}`}>
        <img src={cloud} alt="cloud" />
        <img src={cloud1} alt="cloud1" />
      </div>
    </section>
  );
};

export default HeroSection;
