import React from 'react';
import './statSection.css';
import CountAnimation from '../CountAnimation/CountAnimation';

import { statSample, statSampleMobile } from '../../assets';

const StatSection = () => {
  return (
    <section className="stat-section">
      <div className="stat-img-container">
        <picture>
          <source srcSet={statSample} media="(min-width: 720px)" />
          <img src={statSampleMobile} alt="" />
        </picture>
      </div>
      <div className="stats-container">
        <CountAnimation
          start={0}
          end={60}
          duration={1000}
          caption="projects in New capital"
        />
        <CountAnimation
          start={0}
          end={1000}
          duration={1000}
          caption="Housing Projects"
        />
        <CountAnimation start={0} end={200} duration={1000} caption="Clients" />
      </div>
    </section>
  );
};

export default StatSection;
