import React from 'react';
import { useTitle } from '../../hooks';

import HeroSection from '../../components/HeroSection';
import AboutSummary from '../../components/AboutSummary';
import OurProjects from '../../components/OurProjects';
import ImaketSection from '../../components/ImaketSection';
import OurMap from '../../components/OurMap';
import StatSection from '../../components/StatSection';
import OurClients from '../../components/OurClients';
import GetConsultation from '../../components/GetConsultation';
import Testimonials from '../../components/Testimonials/Testimonials';
import SuliCompound from '../../components/SuliCompound/SuliCompound';

const HomePage = () => {
  useTitle('Home Page');
  return (
    <>
      <HeroSection></HeroSection>
      <AboutSummary />
      <OurProjects />
      <ImaketSection />
      <SuliCompound />
      <OurMap />
      <StatSection />
      <OurClients />
      <Testimonials />
      <GetConsultation />
    </>
  );
};

export default HomePage;
