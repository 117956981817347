import React, { useEffect } from 'react';
import { useTitle } from '../hooks';
import { Link } from 'react-router-dom';
import Banner from '../components/Banner';
import { getAllProjects } from '../utils/api';

const filters = [
  { label: 'Exterior', filter: '.cat-1' },
  { label: 'Interior', filter: '.cat-2' },
  { label: 'Mega Projects', filter: '.cat-3' },
  { label: 'Construction Work', filter: '.cat-4' },
  { label: 'Architecture Drawings', filter: '.cat-5' }
];

const projects = getAllProjects();

var bnrimg = projects[0].images.banner;

const WorkMasonary = () => {
  useTitle('Projects');
  useEffect(() => {
    function loadScript(src) {
      return new Promise(function(resolve, reject) {
        let script = document.createElement('script');
        script.src = src;
        script.addEventListener('load', function() {
          resolve();
        });
        script.addEventListener('error', function(e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript('./assets/js/custom.js');
  }, []);

  return (
    <>
      <div className="page-content">
        <Banner
          title="Planning, design, and historic preservation."
          pagename="Projects"
          bgimage={bnrimg}
        />

        {/* SECTION CONTENT */}
        <div className="section-full p-tb90 tm-work-wrap">
          <div className="container">
            {/* PAGINATION START */}
            <div className="filter-wrap p-b50">
              <ul className="masonry-filter link-style  text-uppercase">
                <li className="active">
                  <a data-filter="*" href="/#">
                    All
                  </a>
                </li>
                {filters.map((item, index) => (
                  <li key={index}>
                    <a data-filter={item.filter} href="/#">
                      {item.label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            {/* PAGINATION END */}
          </div>
          {/* GALLERY CONTENT START */}
          <div className="container-fluid">
            <div className="portfolio-wrap mfp-gallery work-masonry clearfix">
              <div className="row">
                {projects.map(item => (
                  <div
                    key={item.id + item.title}
                    className={`${item.filter} ${item.colWidth} masonry-item col-md-6 col-sm-6 m-b30`}
                  >
                    <Link
                      to={`/projects/${item.id}`}
                      className="text-white font-24 font-weight-300"
                    >
                      <div className="wt-img-effect ">
                        <img src={item.images.thumb} alt="" />

                        <div className="overlay-bx-2 light-bg">
                          <div className="line-amiation  p-a40">
                            <div className="text-white">
                              <h2>
                                <Link
                                  to={`/projects/${item.id}`}
                                  className="text-white font-24 font-weight-300"
                                >
                                  {item.title}
                                </Link>
                              </h2>
                              <Link
                                to={`/projects/${item.id}`}
                                className="v-button letter-spacing-4 font-12 text-uppercase p-l20"
                              >
                                Read More
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* GALLERY CONTENT END */}
        </div>
        {/* SECTION CONTENT END */}
      </div>
    </>
  );
};

export default WorkMasonary;
