import React, { useState } from 'react';
import './imageMagnifier.css';

const ImageMagnifier = ({
  srcSet,
  sizes,
  src,
  width,
  height,
  alt,
  zoomLevel = 1.5
}) => {
  const [[x, y], setXY] = useState([0, 0]);
  const [[imgWidth, imgHeight], setSize] = useState([0, 0]);
  const [showMagnifier, setShowMagnifier] = useState(false);
  const [scale, setScale] = useState(zoomLevel);
  const [magnifieWidth, setMagnifieWidth] = useState(240);
  const [magnifierHeight, setMagnifierHeight] = useState(180);
  return (
    <div
      className="magnifier-lens"
      style={{
        position: 'relative',
        height: height,
        width: width
      }}
    >
      <picture>
        <source srcSet={srcSet} media="(max-width: 720px)" />
        <img
          src={src}
          alt={alt}
          style={{ height: height, width: width }}
          onMouseEnter={e => {
            // update image size and turn-on magnifier
            const elem = e.currentTarget;
            const { width, height } = elem.getBoundingClientRect();
            setMagnifieWidth(width * 0.3);
            setMagnifierHeight(height * 0.3);
            if (width <= 480) setScale(2);

            setSize([width, height]);
            setShowMagnifier(true);
          }}
          onMouseMove={e => {
            // update cursor position
            const elem = e.currentTarget;
            const { top, left } = elem.getBoundingClientRect();

            // calculate cursor position on the image
            const x = e.pageX - left - window.pageXOffset;
            const y = e.pageY - top - window.pageYOffset;
            setXY([x, y]);
          }}
          onMouseLeave={() => {
            // close magnifier
            setShowMagnifier(false);
          }}
        />
      </picture>

      <div
        className="image-magnifier"
        style={{
          display: showMagnifier ? '' : 'none',
          position: 'absolute',

          // prevent maginier blocks the mousemove event of img
          pointerEvents: 'none',
          // set size of magnifier
          height: `${magnifierHeight}px`,
          width: `${magnifieWidth}px`,
          // move element center to cursor pos
          top: `${y - magnifierHeight / 2}px`,
          left: `${x - magnifieWidth / 2}px`,
          opacity: '1', // reduce opacity so you can verify position
          border: '1px solid lightgray',
          backgroundColor: 'white',
          overflow: 'hidden'
        }}
      >
        <picture>
          <source srcSet={srcSet} media="(max-width: 720px)" />
          <img
            src={src}
            style={{
              position: 'absolute',
              width: `${imgWidth * scale}px`,
              height: `${imgHeight * scale}px`,
              left: `${-x * scale + magnifieWidth / 2}px`,
              top: `${-y * scale + magnifierHeight / 2}px`,
              maxWidth: 'unset'
            }}
            alt=""
          />
        </picture>
      </div>
    </div>
  );
};

export default ImageMagnifier;
