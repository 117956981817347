import React from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const members = [
  {
    image: require('../images/our-team5/mohamed tammam.webp'),
    fullname: 'Arch. Mohamed Tammam',
    description:
      'Graduated From HTI in 2010 ,He is responsible for the visual and supervision department'
  },
  {
    image: require('../images/our-team5/mostafa afifi.webp'),
    fullname: 'End. Mostafa Afifi',
    description:
      'Graduated From Al – Azhar university in 2014 , He is responsible for the technical office and supervision department'
  },
  {
    image: require('../images/our-team5/mohamed samir.webp'),
    fullname: 'Eng. Mohamed Samir',
    description:
      'Graduated From HTI in 2010 , He is responsible for the CONSTRUCTION AND supervision department'
  }
];

class TeamMembers2 extends React.Component {
  render() {
    return (
      <>
        <div className="section-full bg-gray p-tb90 square_shape1 tw-our-story-wrap">
          <div className="container">
            {/* TITLE START */}
            <div className="section-head text-left text-black ">
              <h2 className="text-uppercase font-36">Our Experts</h2>
              <div className="wt-separator-outer">
                <div className="wt-separator" />
              </div>
            </div>
            {/* TITLE END */}
            <div className="section-content our-story">
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="wt-media our-story-pic">
                    <img src={members[0].image} alt="" />
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="wt-box our-story-detail">
                    <h2 className="text-uppercase gold">
                      {members[0].fullname}
                    </h2>
                    <p>{members[0].description}</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="wt-box our-story-detail">
                    <h2 className="text-uppercase">{members[1].fullname}</h2>
                    <p>{members[1].description}</p>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="wt-media our-story-pic">
                    <img src={members[1].image} alt="" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="wt-media our-story-pic">
                    <img src={members[2].image} alt="" />
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="wt-box our-story-detail">
                    <h2 className="text-uppercase gold">
                      {members[2].fullname}
                    </h2>
                    <p>{members[2].description}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default TeamMembers2;
