const projects = [
  {
    id: 1,
    title: 'Nile Business City',
    description: {
      par1:
        'Nile Business City is a convenient, practical place where everything is at your fingertips. This is because it offers a comprehensive package of facilities and services that are within easy reach, making business transactions easier and more enjoyable.',
      par2:
        'While you concentrate on achieving your goals, let us handle the minor issues. We propose a bustling boulevard with a full complement of services and amenities set amid contemporary architecture and lush vegetation, bridging the gap between New Cairo and the New Capital and opening the door to an unheard-of business model.'
    },
    images: {
      thumb: require('../images/projects/Nile Business City/nile shot 1-mobile.webp'),
      banner: require('../images/projects/Nile Business City/Nile Buisness Tower Still 04.webp'),
      imgs: [
        require('../images/projects/Nile Business City/nile shot 1.webp'),
        require('../images/projects/Nile Business City/nile shot 4.webp'),
        require('../images/projects/Nile Business City/Nile Buisness Tower Still 08.webp'),
        require('../images/projects/Nile Business City/Nile Buisness Tower Still 11.webp'),
        require('../images/projects/Nile Business City/Nile Buisness Tower Still 04.webp'),
        require('../images/projects/Nile Business City/Nile Buisness Tower Still 05.webp')
      ]
    },
    filter: 'cat-3',
    colWidth: 'col-lg-3'
  },
  {
    id: 2,
    title: 'Floria Compound',
    description: {
      par1:
        'Modern homes share similar characteristics with minimalist interiors. The focus is on simplicity, cleanliness and absence of decor or detailing. Furniture offers smooth, clean lines inspired by architecture from the 1930s. It’s this testament to the sensibilities of the Bauhaus movement and Scandinavian design principles that sets modern interior design apart from contemporary style. Color palettes are typically made up of neutral shades, such as whites, creams and beiges. However, unlike minimalist interiors, these homes contain splashes of bright colors like oranges, pinks and turquoise on items such as dinnerware, curtains and rugs.',
      par2: ''
    },
    images: {
      thumb: require('../images/projects/floria compound/flria shot 15-mobile.webp'),
      banner: require('../images/projects/floria compound/floria shot 03.webp'),
      imgs: [
        require('../images/projects/floria compound/flria shot 15.webp'),
        require('../images/projects/floria compound/flria shot 02.webp'),
        require('../images/projects/floria compound/flria shot 05.webp'),
        require('../images/projects/floria compound/flria shot 12.webp'),
        require('../images/projects/floria compound/floria shot 03.webp'),
        require('../images/projects/floria compound/flria shot 17.webp'),
        require('../images/projects/floria compound/Mall 01.webp')
      ]
    },
    filter: 'cat-3',
    colWidth: 'col-lg-6'
  },
  {
    id: 3,
    title: 'East Tower',
    description: {
      par1:
        'East Tower Project in the New Administrative Capital is a skyscraper located in the central business district and overlooks the iconic tower by UC Real Estate Development. The central business district is one of the most important investment areas in the new capital, designed by the Chinese company CSCEC, and includes 20 commercial, administrative and residential towers, the iconic tower, international banks, hotels, a hospital and a cultural center.',
      par2: ''
    },
    images: {
      thumb: require('../images/projects/East Tower/east tower shot 01-mobile.webp'),
      banner: require('../images/projects/East Tower/east tower shot 10.webp'),
      imgs: [
        require('../images/projects/East Tower/east tower shot 01.webp'),
        require('../images/projects/East Tower/east tower final shot 004.webp'),
        require('../images/projects/East Tower/east tower final shot 01.webp'),
        require('../images/projects/East Tower/east tower shot 10.webp')
      ]
    },
    filter: 'cat-3',
    colWidth: 'col-lg-3'
  },
  {
    id: 4,
    title: 'Lafayette Mall',
    description: {
      par1:
        'The optimal use of the modern style in design is what has been worked on to bring out this character that you see, which was realized during work on the quality and the final form of the design, and it contains all the elements of luxury and all elements of modernity in showing all the building in this appropriate form, Lafayette Mall is The first commercial mall in the new administrative capital in Cairo, and it is one of the most important projects that we have put our silence in its design recently.',
      par2: ''
    },
    images: {
      thumb: require('../images/projects/Lafayette/Lafayette Cam 12-mobile.webp'),
      banner: require('../images/projects/Lafayette/Lafayette Cam 14.webp'),
      imgs: [
        require('../images/projects/Lafayette/Lafayette Cam 12.webp'),
        require('../images/projects/Lafayette/Lafayette Cam 04.webp'),
        require('../images/projects/Lafayette/Lafayette Cam 05.webp'),
        require('../images/projects/Lafayette/Lafayette Cam 14.webp'),
        require('../images/projects/Lafayette/Untitled-1.webp')
      ]
    },
    filter: 'cat-3',
    colWidth: 'col-lg-6'
  },
  {
    id: 5,
    title: 'Rixoz',
    description: {
      par1:
        'Rixos Sharm el-Sheikh is a luxury resort in Nabq Bay where the desert meets the pristine waters of the Red Sea. With stunning views of the Gulf of Aqaba and Tiran Island, the resort stretches out to the palm-fringed golden sands beyond its lush gardens. Rixos Sharm el Sheikh offers 660 luxurious and elegantly decorated rooms, conference facilities, seven swimming pools, one main restaurant, seven a la carte restaurants, six bars, and Spa & Wellness facilities. This is an Ultra All-Inclusive Resort. The resort is located 8 km from Sharm El Sheikh International Airport.',
      par2: ''
    },
    images: {
      thumb: require('../images/projects/Rixoz tower/rexoz shot 4-mobile.webp'),
      banner: require('../images/projects/Rixoz tower/rexoz shot 1.webp'),
      imgs: [
        require('../images/projects/Rixoz tower/rexoz shot 4.webp'),
        require('../images/projects/Rixoz tower/rexoz shot 1.webp'),
        require('../images/projects/Rixoz tower/rexoz shot 2.webp')
      ]
    },
    filter: 'cat-3',
    colWidth: 'col-lg-3'
  },
  {
    id: 6,
    title: '31N Nile Tower',
    description: {
      par1:
        'The optimal use of the modern style in design is what has been worked on to bring out this character that you see, which was realized during work on the quality and the final form of the design, and it contains all the elements of luxury and all elements of modernity in showing all the building in this appropriate form, Lafayette Mall is The first commercial mall in the new administrative capital in Cairo, and it is one of the most important projects that we have put our silence in its design recently.',
      par2: ''
    },
    images: {
      thumb: require('../images/projects/31 North/4-mobile.webp'),
      banner: require('../images/projects/31 North/5.webp'),
      imgs: [
        require('../images/projects/31 North/4.webp'),
        require('../images/projects/31 North/5.webp'),
        require('../images/projects/31 North/2.webp')
      ]
    },
    filter: 'cat-3',
    colWidth: 'col-lg-3'
  },
  {
    id: 7,
    title: 'Zaha Park',
    description: {
      par1:
        'Multi-Purpose Building : 1st Commercial & Administrative Business complex in New Capital , Various Area Unit Starting From 35 M2 , Various Use Starting From Coffee Shops , Banking , Restaurant , Bakeries , lab , pharmacy  and Medical scan .',
      par2: ''
    },
    images: {
      thumb: require('../images/projects/Zaha park/zaha park shot 2-mobile.webp'),
      banner: require('../images/projects/Zaha park/Admin Building Close Cam 03 .webp'),
      imgs: [
        require('../images/projects/Zaha park/zaha park shot 2.webp'),
        require('../images/projects/Zaha park/Admin Building Close Cam 03 .webp'),
        require('../images/projects/Zaha park/zaha park shot 1.webp'),
        require('../images/projects/Zaha park/zaha park shot 30.webp')
      ]
    },
    filter: 'cat-3',
    colWidth: 'col-lg-3'
  },
  {
    id: 8,
    title: 'Amaz Mall',
    description: {
      par1: `Our new masterpiece in the heart of the downtown area in the New Capital.
        AMAZ mall the new Horus Falcon in the east.
        The building’s design has been inspired by the falcon, a majestic creature conveying strength and longevity, and which has been a longstanding symbol of the east’s heritage, and the west’s prosperity.The design embodies a soaring falcon spreading its wings, placing the surrounding landscape and nature in the building’s vicinity as one unit, providing a welcoming aura that intrigues any passer-by.`,
      par2: `The falcon motif has been a major inspiration with all aspects of the project, even down to the building materials.
      A stark contrast can be seen between the heart of the building- which convey strength and durability dressed with intricate geometric details and lighting systems on the facade to add depth to the design- and the ‘wings’, made using more light weight glass and soft touch materials for a streamlined, versatile design mimicking a falcon’s wings.
      Not just a design, every design has its own idea and a story to tell.`
    },
    images: {
      thumb: require('../images/projects/Amaz/el fath still 01-mobile.webp'),
      banner: require('../images/projects/Amaz/el fath still 02.webp'),
      imgs: [
        require('../images/projects/Amaz/el fath still 01.webp'),
        require('../images/projects/Amaz/el fath still 02.webp')
      ]
    },
    filter: 'cat-3',
    colWidth: 'col-lg-3'
  },
  {
    id: 9,
    title: 'Twin Tower',
    description: {
      par1: ``,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Two Towers/4-mobile.webp'),
      banner: require('../images/projects/Two Towers/3.webp'),
      imgs: [
        require('../images/projects/Two Towers/4.webp'),
        require('../images/projects/Two Towers/3.webp'),
        require('../images/projects/Two Towers/2_01.webp')
      ]
    },
    filter: 'cat-3',
    colWidth: 'col-lg-6'
  },
  {
    id: 10,
    title: 'Trio-V',
    description: {
      par1: `Inspired by French art and design from the 1920s, art deco (short for arts decoratifs) is characterized by sleek, metallic finishes like stainless steel, glass and chrome. Unlike vintage style, which highlights floral patterns, rugs and linens feature graphic prints such as zigzags, sunbursts,  and geometric shapes, wall art throughout an art deco-inspired home are defined by jagged, pointed edges. Pieces are also larger . Light fixtures are just as structured as the decor and furniture. They’re typically made of sleek, shiny materials like polished bronze, chrome or steel. Bold geometric patterns are frequently printed on wall sconces, ceiling mounts and lamps. Other angular designs like chevron and zigzags adorn light fixtures. Great examples of Art Deco architecture can be found in the historic district in Miami Beach, Florida where this whimsical style is embraced by many small hotels built in the mid-1920s to early 1940s [Curtis 1982]. Prominent Art Deco designers include Le Corbusier, Eileen Gray, Rene Lalique, Emile-Jacques Ruhlmann and A.M. Cassandre.`,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Trio - v/trio v shot 2-mobile.webp'),
      banner: require('../images/projects/Trio - v/trio v shot 02.webp'),
      imgs: [
        require('../images/projects/Trio - v/trio v shot 03.webp'),
        require('../images/projects/Trio - v/trio v shot 2.webp'),
        require('../images/projects/Trio - v/trio v shot 02.webp')
      ]
    },
    filter: 'cat-3',
    colWidth: 'col-lg-3'
  },
  {
    id: 11,
    title: 'Golden view',
    description: {
      par1: `With a unique modern design, we worked on the Golden View Mall, using the modern architectural style in the design, which is characterized by simplicity and the absence of any complications in the facades, where we used glass facades and sun breakers, with using of curved lines to impart a spirit of modernity to the design of the mall, also the site has a key role in its appearance.`,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/golden view/211231_GKC_Cam_02-mobile.webp'),
      banner: require('../images/projects/golden view/211231_GKC_Cam_04.webp'),
      imgs: [
        require('../images/projects/golden view/211231_GKC_Cam_01.webp'),
        require('../images/projects/golden view/211231_GKC_Cam_02.webp'),
        require('../images/projects/golden view/211231_GKC_Cam_04.webp')
      ]
    },
    filter: 'cat-3',
    colWidth: 'col-lg-3'
  },
  {
    id: 12,
    title: 'The Rook',
    description: {
      par1: `With a unique modern design, we worked on the Golden View Mall, using the modern architectural style in the design, which is characterized by simplicity and the absence of any complications in the facades, where we used glass facades and sun breakers, with using of curved lines to impart a spirit of modernity to the design of the mall, also the site has a key role in its appearance.`,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Rook Mall/Mazaya Still 02-mobile.webp'),
      banner: require('../images/projects/Rook Mall/Mazaya Still 12.webp'),
      imgs: [
        require('../images/projects/Rook Mall/Mazaya Still 02.webp'),
        require('../images/projects/Rook Mall/Mazaya Still 03.webp'),
        require('../images/projects/Rook Mall/Mazaya Still 12.webp')
      ]
    },
    filter: 'cat-3',
    colWidth: 'col-lg-3'
  },
  {
    id: 13,
    title: 'Verity',
    description: {
      par1: ``,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Verity Tower/ncb shot 04-mobile.webp'),
      banner: require('../images/projects/Verity Tower/ncb shot 03.webp'),
      imgs: [
        require('../images/projects/Verity Tower/ncb shot 04.webp'),
        require('../images/projects/Verity Tower/ncb shot 02.webp'),
        require('../images/projects/Verity Tower/ncb shot 03.webp')
      ]
    },
    filter: 'cat-3',
    colWidth: 'col-lg-3'
  },
  {
    id: 14,
    title: 'Clock Tower',
    description: {
      par1: ``,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Clock Tower/clock still 04-mobile.webp'),
      banner: require('../images/projects/Clock Tower/clock still 03.webp'),
      imgs: [
        require('../images/projects/Clock Tower/clock still 04.webp'),
        require('../images/projects/Clock Tower/clock still 02.webp'),
        require('../images/projects/Clock Tower/clock still 03.webp'),
        require('../images/projects/Clock Tower/clock still 01.webp')
      ]
    },
    filter: 'cat-3',
    colWidth: 'col-lg-6'
  },
  {
    id: 15,
    title: 'Torath',
    description: {
      par1: ``,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Torath mall/CAM-02-mobile.webp'),
      banner: require('../images/projects/Torath mall/Cam-01.webp'),
      imgs: [
        require('../images/projects/Torath mall/CAM-02.webp'),
        require('../images/projects/Torath mall/Cam-01.webp')
      ]
    },
    filter: 'cat-3',
    colWidth: 'col-lg-3'
  },
  {
    id: 16,
    title: 'Central point',
    description: {
      par1: ``,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/central point/88 back screen-mobile.webp'),
      banner: require('../images/projects/central point/02.webp'),
      imgs: [
        require('../images/projects/central point/03.webp'),
        require('../images/projects/central point/02.webp'),
        require('../images/projects/central point/88 back screen.webp'),
        require('../images/projects/central point/04.webp')
      ]
    },
    filter: 'cat-3',
    colWidth: 'col-lg-3'
  },
  {
    id: 17,
    title: 'UNI Tower 1,2',
    description: {
      par1: `This mid-century style combines modernity with idealistic minimalism. Unlike angular art deco, art moderne (a.k.a. streamline moderne) designs are tapered, rounded and have a more horizontal emphasis. Frequently, they are adorned with parallel “flow” or “speed” lines [Cogdell 2010]. Floor and table lamps are popular light fixtures in the art moderne style. These lamps commonly feature frosted glass and shades are circular and also made of glass. Prominent Art Moderne designers include Raymond Loewy, Paul Frankl, Norman Bel Geddes and Gilbert Rohde.`,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/uni tower/F-2-mobile.webp'),
      banner: require('../images/projects/uni tower/F-1.webp'),
      imgs: [
        require('../images/projects/uni tower/F-2.webp'),
        require('../images/projects/uni tower/F-3.webp'),
        require('../images/projects/uni tower/F-1.webp')
      ]
    },
    filter: 'cat-3',
    colWidth: 'col-lg-3'
  },
  {
    id: 18,
    title: 'M Business Tower',
    description: {
      par1: ``,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/M business tower/M mall shot 02.png2-mobile.webp'),
      banner: require('../images/projects/M business tower/M mall shot 05.webp'),
      imgs: [
        require('../images/projects/M business tower/M mall shot 02.png2.webp'),
        require('../images/projects/M business tower/M mall shot 01.webp'),
        require('../images/projects/M business tower/M mall shot 04.webp'),
        require('../images/projects/M business tower/M mall shot 05.webp')
      ]
    },
    filter: 'cat-3',
    colWidth: 'col-lg-3'
  },
  {
    id: 19,
    title: 'Pixel',
    description: {
      par1: ``,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/pixel mall/pixel mall shot 01-mobile.webp'),
      banner: require('../images/projects/pixel mall/pixel mall shot 02.webp'),
      imgs: [
        require('../images/projects/pixel mall/pixel mall shot 01.webp'),
        require('../images/projects/pixel mall/pixel mall shot 04.webp'),
        require('../images/projects/pixel mall/pixel mall shot 02.webp')
      ]
    },
    filter: 'cat-3',
    colWidth: 'col-lg-3'
  },
  {
    id: 19,
    title: 'Oxygen Mall',
    description: {
      par1: `The art nouveau home design style is similar to art deco in that it features decorative detailing combined with contemporary characteristics. However, this aesthetic is defined by flowing lines, rather than strict, geometric shapes. Door and window frames, along with furniture all have elegant curved edges which vary from subtle undulations to exaggerated fantastical contours. Its symbols and motifs are abstracted from organic vegetation and “a repertoire of biomorphic forms; much of its imagery is directly or obliquely feminine” [Banham 1997]. Other common elements of art nouveau design include floral patterns, stained glass windows, oriental rugs and wrought iron light fixtures and staircase railings. Lighting is typically made up of hand-blown glass or semi-precious stones and incorporates natural elements like vines, birds, branches and florals. Prominent Art Nouveau Designers include Hector Guimard, Louis Comfort Tiffany, Louis Majorelle and Carlo Bugatti.`,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/oxygen mall/oxygen mall render 02-mobile.webp'),
      banner: require('../images/projects/oxygen mall/oxygen mall render 04.webp'),
      imgs: [
        require('../images/projects/oxygen mall/oxygen mall render 02.webp'),
        require('../images/projects/oxygen mall/oxygen mall render 01.webp'),
        require('../images/projects/oxygen mall/oxygen mall render 04.webp')
      ]
    },
    filter: 'cat-3',
    colWidth: 'col-lg-3'
  },
  {
    id: 20,
    title: 'H Mall',
    description: {
      par1: `A mixed-use mall that administrative units and commercial units on an area of 4,700 m2, in the Downtown area in the heart of the New Administrative Capital. A unique and distinctive location, that has a double-face view on two sides, one on the data center and one on the central park. It is easy-to-access location with a 75m width road that connects between the Bin Zayed axis, the governmental neighborhood and the Suez road. The mall is located near the Gold Market, Green River, Monorail Station, tourist walkway, petroleum and insurance companies in addition to the Government District.`,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/H-Mall/cam_4-mobile.webp'),
      banner: require('../images/projects/H-Mall/Cam_2.webp'),
      imgs: [
        require('../images/projects/H-Mall/Cam_1.webp'),
        require('../images/projects/H-Mall/cam_4.webp'),
        require('../images/projects/H-Mall/Cam_2.webp')
      ]
    },
    filter: 'cat-3',
    colWidth: 'col-lg-3'
  },
  {
    id: 21,
    title: 'Harmony',
    description: {
      par1: ``,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Harmony Mall/04-mobile.webp'),
      banner: require('../images/projects/Harmony Mall/07.webp'),
      imgs: [
        require('../images/projects/Harmony Mall/04.webp'),
        require('../images/projects/Harmony Mall/07.webp'),
        require('../images/projects/Harmony Mall/06.webp'),
        require('../images/projects/Harmony Mall/01.webp')
      ]
    },
    filter: 'cat-3',
    colWidth: 'col-lg-3'
  },
  {
    id: 22,
    title: 'Trave',
    description: {
      par1: `Trave Business Complex is located on the central Park overlooking Downtown on a main axis separating street with a width of 70 meters In front of the pharmaceutical companies on an area of 37 acres, the computer companies on 22 acres and next to the government district, as it is designated as the headquarters of the Egyptian government, the parliament & the Senate with an area space of 360 acres.`,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Trave tower/trave mall shot 4-mobile.webp'),
      banner: require('../images/projects/Trave tower/trave mall shot 5 high res dayyy.webp'),
      imgs: [
        require('../images/projects/Trave tower/trave mall shot 4.webp'),
        require('../images/projects/Trave tower/trave mall shot 5 high res dayyy.webp'),
        require('../images/projects/Trave tower/trave night shot editt.webp')
      ]
    },
    filter: 'cat-3',
    colWidth: 'col-lg-3'
  },
  {
    id: 23,
    title: 'Paris Mall',
    description: {
      par1: `Inspired by French art and design from the 1920s, art deco (short for arts decoratifs) is characterized by sleek, metallic finishes like stainless steel, glass and chrome.`,
      par2: `They’re typically made of sleek, shiny materials like polished bronze, chrome or steel. Bold geometric patterns are frequently printed on wall sconces, ceiling mounts and lamps. Other angular designs like chevron and zigzags adorn light fixtures. Great examples of Art Deco architecture can be found in the historic district in Miami Beach, Florida where this whimsical style is embraced by many small hotels built in the mid-1920s to early 1940s [Curtis 1982].`
    },
    images: {
      thumb: require('../images/projects/Paris malls/Paris Cam-2-mobile.webp'),
      banner: require('../images/projects/Paris malls/Paris Cam 02.png0.webp'),
      imgs: [
        require('../images/projects/Paris malls/Paris Cam-3.webp'),
        require('../images/projects/Paris malls/Paris Cam-2.webp'),
        require('../images/projects/Paris malls/Paris Cam 02.png0.webp')
      ]
    },
    filter: 'cat-3',
    colWidth: 'col-lg-3'
  },
  {
    id: 24,
    title: 'Evolve',
    description: {
      par1: `Evolve Tower is among our latest modern designs`,
      par2: `The design was built in the spirit of the twins, where the opposite form appears in the building, and in the middle of them are the corridors in an architectural phenomenon in which the full meaning of modernity appears.
      Also, glossy glass, iron and golden aluminum edges were used in the external facades to complete these components in the spirit of the modern style.`
    },
    images: {
      thumb: require('../images/projects/Evolve/received_475766080375655.jpeg23-mobile.webp'),
      banner: require('../images/projects/Evolve/Evolve Test 12.webp'),
      imgs: [
        require('../images/projects/Evolve/Evolve Test 10.webp'),
        require('../images/projects/Evolve/received_475766080375655.jpeg23.webp'),
        require('../images/projects/Evolve/Evolve Test 12.webp')
      ]
    },
    filter: 'cat-3',
    colWidth: 'col-lg-3'
  },
  {
    id: 25,
    title: 'Evora',
    description: {
      par1: ``,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/evora/rovan shot 06-mobile.webp'),
      banner: require('../images/projects/evora/rovan shot 03.webp'),
      imgs: [
        require('../images/projects/evora/rovan shot 06.webp'),
        require('../images/projects/evora/Rovan Cam 02.webp'),
        require('../images/projects/evora/rovan shot 03.webp'),
        require('../images/projects/evora/rovan shot 02.webp')
      ]
    },
    filter: 'cat-3',
    colWidth: 'col-lg-3'
  },
  {
    id: 26,
    title: 'Down Town Mall',
    description: {
      par1: `Inspired by French art and design from the 1920s, art deco (short for arts decoratifs) is characterized by sleek, metallic finishes like stainless steel, glass and chrome. Unlike vintage style, which highlights floral patterns, rugs and linens feature graphic prints such as zigzags, sunbursts,  and geometric shapes, wall art throughout an art deco-inspired home are defined by jagged, pointed edges. Pieces are also larger . Light fixtures are just as structured as the decor and furniture. They’re typically made of sleek, shiny materials like polished bronze, chrome or steel. Bold geometric patterns are frequently printed on wall sconces, ceiling mounts and lamps. Other angular designs like chevron and zigzags adorn light fixtures. Great examples of Art Deco architecture can be found in the historic district in Miami Beach, Florida where this whimsical style is embraced by many small hotels built in the mid-1920s to early 1940s [Curtis 1982]. Prominent Art Deco designers include Le Corbusier, Eileen Gray, Rene Lalique, Emile-Jacques Ruhlmann and A.M. Cassandre.`,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Down Town/shot  -01-mobile.webp'),
      banner: require('../images/projects/Down Town/shot  -01.webp'),
      imgs: [
        require('../images/projects/Down Town/51-Down-Town-Final-Cam-01-Sunny ps.webp'),
        require('../images/projects/Down Town/shot  -01.webp')
      ]
    },
    filter: 'cat-3',
    colWidth: 'col-lg-3'
  },
  {
    id: 27,
    title: 'Jaya Complex',
    description: {
      par1: `This mid-century style combines modernity with idealistic minimalism. Unlike angular art deco, art moderne (a.k.a. streamline moderne) designs are tapered, rounded and have a more horizontal emphasis. Frequently, they are adorned with parallel “flow” or “speed” lines [Cogdell 2010]. Art Moderne homes feature an all-white color palette and rely on smooth surfaces with little decorative detailing. Doorways, windows and other architectural elements come with curved corners. Raw, natural textures such as metal, terra cotta tile, concrete and glass are all mixed and matched throughout the home as well. The key to art moderne is simplicity – decorative patterns are scarce. Instead, solid neutrals really help to make a strong statement. Furniture is stripped down and typically emulates a sense of motion with pieces like tiered tables. Floor and table lamps are popular light fixtures in the art moderne style. These lamps commonly feature frosted glass and shades are circular and also made of glass. Prominent Art Moderne designers include Raymond Loewy, Paul Frankl, Norman Bel Geddes and Gilbert Rohde.`,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Jaya Complex Motawroon/CAM-04-mobile.webp'),
      banner: require('../images/projects/Jaya Complex Motawroon/CAM-01 fix edit.webp'),
      imgs: [
        require('../images/projects/Jaya Complex Motawroon/CAM-04.webp'),
        require('../images/projects/Jaya Complex Motawroon/CAM-03.webp'),
        require('../images/projects/Jaya Complex Motawroon/CAM-01 fix edit.webp')
      ]
    },
    filter: 'cat-3',
    colWidth: 'col-lg-3'
  },
  {
    id: 28,
    title: 'Grand Square',
    description: {
      par1: `Modernist style, otherwise known as international style, was inspired by 1920s architecture. It focuses on natural materials like glass, steel and reinforced concrete, which are used on floors, countertops and appliances because of their lack of detail and ornamentation. Geometric shapes are emphasized through the use of tables, chairs and desks. Fabrics like curtains and bed linens feature a balanced mix of solids and bold, graphic patterns. As for modernist color palettes, gray is a commonly used hue due to its versatility and its sophisticated, upscale look.`,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Grand Square/Grand Square Cam 09-mobile.webp'),
      banner: require('../images/projects/Grand Square/Grand Square Cam 03 Still 2.webp'),
      imgs: [
        require('../images/projects/Grand Square/Grand Square Cam 09.webp'),
        require('../images/projects/Grand Square/Grand Square Cam 03 Still 2.webp')
      ]
    },
    filter: 'cat-3',
    colWidth: 'col-lg-3'
  },
  {
    id: 29,
    title: 'Acacia Mall',
    description: {
      par1: ``,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/acacia/acacia mall shot 03-mobile.webp'),
      banner: require('../images/projects/acacia/acacia mall roof shot 04.webp'),
      imgs: [
        require('../images/projects/acacia/acacia mall shot 02.webp'),
        require('../images/projects/acacia/acacia mall roof shot 04.webp'),
        require('../images/projects/acacia/acacia mall shot 03.webp'),
        require('../images/projects/acacia/acacia mall shot 04.webp')
      ]
    },
    filter: 'cat-3',
    colWidth: 'col-lg-3'
  },
  {
    id: 30,
    title: 'Champs Elysees',
    description: {
      par1: ``,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/champs-elysees/shanzelize Still 7-mobile.webp'),
      banner: require('../images/projects/champs-elysees/shanzelize Still 08.webp'),
      imgs: [
        require('../images/projects/champs-elysees/shanzelize Still 7.webp'),
        require('../images/projects/champs-elysees/shanzelize Still 08.webp')
      ]
    },
    filter: 'cat-3',
    colWidth: 'col-lg-3'
  },
  {
    id: 31,
    title: 'Dorado Mall',
    description: {
      par1: ``,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Dorado Mall/F-3-mobile.webp'),
      banner: require('../images/projects/Dorado Mall/F-4.webp'),
      imgs: [
        require('../images/projects/Dorado Mall/F-3.webp'),
        require('../images/projects/Dorado Mall/F-1.webp'),
        require('../images/projects/Dorado Mall/F-4.webp')
      ]
    },
    filter: 'cat-3',
    colWidth: 'col-lg-3'
  },
  {
    id: 32,
    title: 'Evira Mall',
    description: {
      par1: ``,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Evira Mall/Evira final Cam 01-1-mobile.webp'),
      banner: require('../images/projects/Evira Mall/Evira final Cam 03.webp'),
      imgs: [
        require('../images/projects/Evira Mall/Evira final Cam 01-1.webp'),
        require('../images/projects/Evira Mall/Evira final Cam 2.webp'),
        require('../images/projects/Evira Mall/Evira final Cam 03.webp')
      ]
    },
    filter: 'cat-3',
    colWidth: 'col-lg-3'
  },
  {
    id: 33,
    title: 'Gemini',
    description: {
      par1: ``,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Gemini/mazya final edit shot 01-mobile.webp'),
      banner: require('../images/projects/Gemini/mazya final edit shot 02.webp'),
      imgs: [
        require('../images/projects/Gemini/mazya final edit shot 01.webp'),
        require('../images/projects/Gemini/mazya final edit shot 02.webp'),
        require('../images/projects/Gemini/mazya final edit shot 04 night.webp')
      ]
    },
    filter: 'cat-3',
    colWidth: 'col-lg-3'
  },
  {
    id: 34,
    title: 'Golden Tower',
    description: {
      par1: ``,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/golden tower/Golden tower 10-mobile.webp'),
      banner: require('../images/projects/golden tower/Golden tower 11.webp'),
      imgs: [
        require('../images/projects/golden tower/Golden tower 09.webp'),
        require('../images/projects/golden tower/Golden tower 10.webp'),
        require('../images/projects/golden tower/Golden tower 11.webp'),
        require('../images/projects/golden tower/Golden tower 14.webp')
      ]
    },
    filter: 'cat-3',
    colWidth: 'col-lg-3'
  },
  {
    id: 35,
    title: 'Inizio',
    description: {
      par1: ``,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Inizio/5-mobile.webp'),
      banner: require('../images/projects/Inizio/4.webp'),
      imgs: [
        require('../images/projects/Inizio/2.webp'),
        require('../images/projects/Inizio/5.webp'),
        require('../images/projects/Inizio/4.webp')
      ]
    },
    filter: 'cat-3',
    colWidth: 'col-lg-3'
  },
  {
    id: 36,
    title: 'N mall',
    description: {
      par1: ``,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/N mall/N-Mall-003-mobile.webp'),
      banner: require('../images/projects/N mall/N-Mall-005.webp'),
      imgs: [
        require('../images/projects/N mall/N-Mall-003.webp'),
        require('../images/projects/N mall/N-Mall-002.webp'),
        require('../images/projects/N mall/N-Mall-005.webp')
      ]
    },
    filter: 'cat-3',
    colWidth: 'col-lg-3'
  },
  {
    id: 37,
    title: 'Smart Mall',
    description: {
      par1: ``,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Smart Mall/6--mobile.webp'),
      banner: require('../images/projects/Smart Mall/3-.webp'),
      imgs: [
        require('../images/projects/Smart Mall/6-.webp'),
        require('../images/projects/Smart Mall/1-.webp'),
        require('../images/projects/Smart Mall/3-.webp')
      ]
    },
    filter: 'cat-3',
    colWidth: 'col-lg-3'
  },
  {
    id: 38,
    title: 'Smart Tower',
    description: {
      par1: ``,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Smart Tower/smart mall edit-mobile.webp'),
      banner: require('../images/projects/Smart Tower/smart tower shot 1.webp'),
      imgs: [
        require('../images/projects/Smart Tower/smart mall edit.webp'),
        require('../images/projects/Smart Tower/smart tower shot 1.webp')
      ]
    },
    filter: 'cat-3',
    colWidth: 'col-lg-3'
  },
  {
    id: 39,
    title: 'Suli Compound',
    description: {
      par1: ``,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Suli compound/UC Compound Cam 01-mobile.webp'),
      banner: require('../images/projects/Suli compound/outdoor shot 10000.webp'),
      imgs: [
        require('../images/projects/Suli compound/UC Compound Landscape Cam 01.RGB_color.0000 (0-00-00-00)_1.webp'),
        require('../images/projects/Suli compound/UC Compound Golf Still 02.RGB_color.0000.webp'),
        require('../images/projects/Suli compound/UC Compound Golf Still 01.RGB_color.0000.webp'),
        require('../images/projects/Suli compound/UC Compound Cam 03.webp'),
        require('../images/projects/Suli compound/UC Compound Cam 01.webp'),
        require('../images/projects/Suli compound/outdoor shot 10000.webp')
      ]
    },
    filter: 'cat-3',
    colWidth: 'col-lg-3'
  },
  {
    id: 40,
    title: 'Fourseason Head Quarter',
    description: {
      par1: ``,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Interior/Fourseason Head Quarter/03-4-recption--mobile.webp'),
      banner: require('../images/projects/Interior/Fourseason Head Quarter/03-2-recption-.webp'),
      imgs: [
        require('../images/projects/Interior/Fourseason Head Quarter/03-4-recption-.webp'),
        require('../images/projects/Interior/Fourseason Head Quarter/03-2-recption-.webp'),
        require('../images/projects/Interior/Fourseason Head Quarter/01-1-meeting-room.webp'),
        require('../images/projects/Interior/Fourseason Head Quarter/01-2-meeting-room.webp'),
        require('../images/projects/Interior/Fourseason Head Quarter/02-1-c.webp'),
        require('../images/projects/Interior/Fourseason Head Quarter/02-3-c.webp'),
        require('../images/projects/Interior/Fourseason Head Quarter/03-1-recption-.webp'),
        require('../images/projects/Interior/Fourseason Head Quarter/03-3-reception.webp')
      ]
    },
    filter: 'cat-2',
    colWidth: 'col-lg-3'
  },
  {
    id: 41,
    title: 'N Mall Interior',
    description: {
      par1: `Inspired by French art and design from the 1920s, art deco (short for arts decoratifs) is characterized by sleek, metallic finishes like stainless steel, glass and chrome. Unlike vintage style, which highlights floral patterns, rugs and linens feature graphic prints such as zigzags, sunbursts, animal prints and geometric shapes. Furniture and wall art throughout an art deco-inspired home are defined by jagged, pointed edges. Pieces are also larger – generously sized chairs, armoires, dressers and couches are all typical of the 1920s. Light fixtures are just as structured as the decor and furniture. They’re typically made of sleek, shiny materials like polished bronze, chrome or steel. Bold geometric patterns are frequently printed on wall sconces, ceiling mounts and lamps. Other angular designs like chevron and zigzags adorn light fixtures. Great examples of Art Deco architecture can be found in the historic district in Miami Beach, Florida where this whimsical style is embraced by many small hotels built in the mid-1920s to early 1940s [Curtis 1982]. Prominent Art Deco designers include Le Corbusier, Eileen Gray, Rene Lalique, Emile-Jacques Ruhlmann and A.M. Cassandre.`,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Interior/N Mall/Foodcourt-2-mobile.webp'),
      banner: require('../images/projects/Interior/N Mall/Roof-Garden.webp'),
      imgs: [
        require('../images/projects/Interior/N Mall/Foodcourt-2.webp'),
        require('../images/projects/Interior/N Mall/Roof-Garden.webp'),
        require('../images/projects/Interior/N Mall/Foodcourt-1.webp'),
        require('../images/projects/Interior/N Mall/Offices-1.webp'),
        require('../images/projects/Interior/N Mall/Offices-2.webp'),
        require('../images/projects/Interior/N Mall/Offices-3.webp')
      ]
    },
    filter: 'cat-2',
    colWidth: 'col-lg-3'
  },
  {
    id: 42,
    title: 'Metropolis Headquarter',
    description: {
      par1: ``,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Interior/Metropolis Headquarter/13-mobile.webp'),
      banner: require('../images/projects/Interior/Metropolis Headquarter/17.webp'),
      imgs: [
        require('../images/projects/Interior/Metropolis Headquarter/13.webp'),
        require('../images/projects/Interior/Metropolis Headquarter/17.webp'),
        require('../images/projects/Interior/Metropolis Headquarter/15-1.webp'),
        require('../images/projects/Interior/Metropolis Headquarter/19.webp')
      ]
    },
    filter: 'cat-2 cat-4',
    colWidth: 'col-lg-3'
  },
  {
    id: 43,
    title: 'New Cairo Developers Headquarter',
    description: {
      par1: ``,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Interior/New Cairo Developers Headquarter/00-mobile.webp'),
      banner: require('../images/projects/Interior/New Cairo Developers Headquarter/12.webp'),
      imgs: [
        require('../images/projects/Interior/New Cairo Developers Headquarter/00.webp'),
        require('../images/projects/Interior/New Cairo Developers Headquarter/12.webp'),
        require('../images/projects/Interior/New Cairo Developers Headquarter/02-2.webp'),
        require('../images/projects/Interior/New Cairo Developers Headquarter/04-2.webp'),
        require('../images/projects/Interior/New Cairo Developers Headquarter/06-2.webp'),
        require('../images/projects/Interior/New Cairo Developers Headquarter/08.webp'),
        require('../images/projects/Interior/New Cairo Developers Headquarter/10-1.webp')
      ]
    },
    filter: 'cat-2 cat-4',
    colWidth: 'col-lg-3'
  },
  {
    id: 44,
    title: 'Acacia Mall',
    description: {
      par1: `Inspired by French art and design from the 1920s, art deco (short for arts decoratifs) is characterized by sleek, metallic finishes like stainless steel, glass and chrome.`,
      par2: `They’re typically made of sleek, shiny materials like polished bronze, chrome or steel. Bold geometric patterns are frequently printed on wall sconces, ceiling mounts and lamps. Other angular designs like chevron and zigzags adorn light fixtures. Great examples of Art Deco architecture can be found in the historic district in Miami Beach, Florida where this whimsical style is embraced by many small hotels built in the mid-1920s to early 1940s [Curtis 1982].`
    },
    images: {
      thumb: require('../images/projects/Interior/Acacia Mall/acacia-mall-interior-02-mobile.webp'),
      banner: require('../images/projects/Interior/Acacia Mall/acacia-mall-interior.webp'),
      imgs: [
        require('../images/projects/Interior/Acacia Mall/acacia-mall-interior-02.webp'),
        require('../images/projects/Interior/Acacia Mall/acacia-mall-interior.webp'),
        require('../images/projects/Interior/Acacia Mall/acacia-mall-roof-shot-03.webp'),
        require('../images/projects/Interior/Acacia Mall/acacia-office.webp'),
        require('../images/projects/Interior/Acacia Mall/acacia-restorant.webp')
      ]
    },
    filter: 'cat-2',
    colWidth: 'col-lg-3'
  },
  {
    id: 45,
    title: 'Bonian Interior',
    description: {
      par1: `Mexican-inspired homes deliver a similar feel to eclectic interiors. Numerous bold colors are used, such as bright pinks, greens, oranges, reds and yellows. Candlesticks, ceramic pots, ironware and hand-sewn rugs also bring personality into a Mexican home. The pottery often has intricate and colorful designs influenced by the flora and fauna found in Mexico. Chairs, tables and sofas boast an elegant look, with influences of Spanish colonial and Tuscan style. Mexican furniture can either come with a refined, stained dark wood or weathered wood for a more casual, rustic beauty. Prints and patterns like mosaic can be found on everything from tiles to pillows.`,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Interior/Bonian Interior/Rec-3-04-mobile.webp'),
      banner: require('../images/projects/Interior/Bonian Interior/Rec-3-03.webp'),
      imgs: [
        require('../images/projects/Interior/Bonian Interior/Rec-3-04.webp'),
        require('../images/projects/Interior/Bonian Interior/Rec-3-03.webp'),
        require('../images/projects/Interior/Bonian Interior/Rec-3-01.webp'),
        require('../images/projects/Interior/Bonian Interior/Rec-3-02.webp'),
        require('../images/projects/Interior/Bonian Interior/Rec-3-05.webp')
      ]
    },
    filter: 'cat-2',
    colWidth: 'col-lg-3'
  },
  {
    id: 46,
    title: 'Pyramids Head Quarter',
    description: {
      par1: ``,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Interior/Pyramids Headquarter/28-mobile.webp'),
      banner: require('../images/projects/Interior/Pyramids Headquarter/15.webp'),
      imgs: [
        require('../images/projects/Interior/Pyramids Headquarter/28.webp'),
        require('../images/projects/Interior/Pyramids Headquarter/15.webp'),
        require('../images/projects/Interior/Pyramids Headquarter/03-1-e1667508829231.webp'),
        require('../images/projects/Interior/Pyramids Headquarter/10.webp'),
        require('../images/projects/Interior/Pyramids Headquarter/16.webp'),
        require('../images/projects/Interior/Pyramids Headquarter/20.webp'),
        require('../images/projects/Interior/Pyramids Headquarter/23.webp'),
        require('../images/projects/Interior/Pyramids Headquarter/35.webp'),
        require('../images/projects/Interior/Pyramids Headquarter/43.webp')
      ]
    },
    filter: 'cat-2 cat-4',
    colWidth: 'col-lg-3'
  },
  {
    id: 47,
    title: 'El Narges Residence',
    description: {
      par1: `Neoclassic homes evoke a timeless, elegant and historical aesthetic. This interior design motif shares certain characteristics to other home styles including Greek and Roman. Decorating pieces like vases, statues and paintings feature themes from Greek mythology and front porches have massive symmetrical columns inspired by Greek and Roman architecture. Walls are decorated with large vintage mirrors surrounded by ornate gilded frames. Interior colors are light and subtle and include pale blues, creams, grays, yellows and greens. Black and gold are used as bold contrasting hues. Furniture is simple and constructed out of dark wood. Persian rugs cover the floors and luxurious silk and cotton fabrics are seen on curtains and linens.`,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Interior/El Narges Residence/3-1-mobile.webp'),
      banner: require('../images/projects/Interior/El Narges Residence/2-1.webp'),
      imgs: [
        require('../images/projects/Interior/El Narges Residence/3-1.webp'),
        require('../images/projects/Interior/El Narges Residence/2-1.webp'),
        require('../images/projects/Interior/El Narges Residence/1-1-1.webp'),
        require('../images/projects/Interior/El Narges Residence/01-2.webp'),
        require('../images/projects/Interior/El Narges Residence/02-2.webp'),
        require('../images/projects/Interior/El Narges Residence/03-2.webp'),
        require('../images/projects/Interior/El Narges Residence/4-1.webp'),
        require('../images/projects/Interior/El Narges Residence/04-1.webp'),
        require('../images/projects/Interior/El Narges Residence/5-1.webp'),
        require('../images/projects/Interior/El Narges Residence/360-1-1.webp'),
        require('../images/projects/Interior/El Narges Residence/360-2-1.webp'),
        require('../images/projects/Interior/El Narges Residence/360-3.webp')
      ]
    },
    filter: 'cat-2',
    colWidth: 'col-lg-3'
  },
  {
    id: 48,
    title: 'I 17 Enterance',
    description: {
      par1: ``,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Interior/I 17 Enterance/i-17-centro-entrance-mobile.webp'),
      banner: require('../images/projects/Interior/I 17 Enterance/i-17-centro-entrance-03.webp'),
      imgs: [
        require('../images/projects/Interior/I 17 Enterance/i-17-centro-entrance.webp'),
        require('../images/projects/Interior/I 17 Enterance/i-17-centro-entrance-03.webp')
      ]
    },
    filter: 'cat-2',
    colWidth: 'col-lg-3'
  },
  {
    id: 49,
    title: 'Scope Entrance',
    description: {
      par1: ``,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Interior/Scope Enterance/SCOPE.jpg-s-mobile.webp'),
      banner: require('../images/projects/Interior/Scope Enterance/SCOPE.jpg-s.webp'),
      imgs: [
        require('../images/projects/Interior/Scope Enterance/SCOPE.jpg-s.webp')
      ]
    },
    filter: 'cat-2',
    colWidth: 'col-lg-3'
  },
  {
    id: 50,
    title: 'La Capital Enterance',
    description: {
      par1: `The key to art modern is simplicity – decorative patterns are scarce. Instead, solid neutrals really help to make a strong statement. Furniture is stripped down and typically emulates a sense of motion with pieces like tiered tables. Chairs and sofas also have rounded edges and use contrasting material colors like black and white. Surfaces are glossy to give the furniture a modern feel. Floor and table lamps are popular light fixtures in the art modern style. These lamps commonly feature frosted glass and shades are circular and also made of glass.`,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Interior/La Capital Enterance/2--mobile.webp'),
      banner: require('../images/projects/Interior/La Capital Enterance/1-.webp'),
      imgs: [
        require('../images/projects/Interior/La Capital Enterance/2-.webp'),
        require('../images/projects/Interior/La Capital Enterance/1-.webp')
      ]
    },
    filter: 'cat-2',
    colWidth: 'col-lg-3'
  },
  {
    id: 51,
    title: 'La Capital Studio Enterance',
    description: {
      par1: `The key to art modern is simplicity – decorative patterns are scarce. Instead, solid neutrals really help to make a strong statement. Furniture is stripped down and typically emulates a sense of motion with pieces like tiered tables. Chairs and sofas also have rounded edges and use contrasting material colors like black and white. Surfaces are glossy to give the furniture a modern feel. Floor and table lamps are popular light fixtures in the art modern style. These lamps commonly feature frosted glass and shades are circular and also made of glass.`,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Interior/La Capital Studio Enterance/Lacapital-Studio-Option1-Cam-02-mobile.webp'),
      banner: require('../images/projects/Interior/La Capital Studio Enterance/Lacapital-Studio-Option1-Cam-04.webp'),
      imgs: [
        require('../images/projects/Interior/La Capital Studio Enterance/Lacapital-Studio-Option1-Cam-02.webp'),
        require('../images/projects/Interior/La Capital Studio Enterance/Lacapital-Studio-Option1-Cam-04.webp'),
        require('../images/projects/Interior/La Capital Studio Enterance/Lacapital-Studio-Option-1-.webp'),
        require('../images/projects/Interior/La Capital Studio Enterance/Lacapital-Studio-Option1-Cam-01.webp'),
        require('../images/projects/Interior/La Capital Studio Enterance/Lacapital-Studio-Option1-Cam-02-1-.webp'),
        require('../images/projects/Interior/La Capital Studio Enterance/Lacapital-Studio-Option1-Cam-03-1-.webp'),
        require('../images/projects/Interior/La Capital Studio Enterance/Lacapital-Studio-Option1-Cam-03.webp')
      ]
    },
    filter: 'cat-2',
    colWidth: 'col-lg-3'
  },
  {
    id: 52,
    title: 'Grand Square Interior',
    description: {
      par1: `Modernist style, otherwise known as international style, was inspired by 1920s architecture. It focuses on natural materials like glass, steel and reinforced concrete, which are used on floors, countertops and appliances because of their lack of detail and ornamentation. Geometric shapes are emphasized through the use of tables, chairs and desks. Fabrics like curtains and bed linens feature a balanced mix of solids and bold, graphic patterns. As for modernist color palettes, gray is a commonly used hue due to its versatility and its sophisticated, upscale look.`,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Interior/Grand Square Interior/Grand-Square-Int-Cam-02-mobile.webp'),
      banner: require('../images/projects/Interior/Grand Square Interior/Grandsquare-Int-Cam-01.webp'),
      imgs: [
        require('../images/projects/Interior/Grand Square Interior/Grand-Square-Int-Cam-02.webp'),
        require('../images/projects/Interior/Grand Square Interior/Grandsquare-Int-Cam-01.webp')
      ]
    },
    filter: 'cat-2',
    colWidth: 'col-lg-3'
  },
  {
    id: 53,
    title: 'J 44 Beet El Watan',
    description: {
      par1: `Regence inspired interiors combine glamour and sophistication with French style. Unlike regency homes, color palettes are kept more subdued using a mixture of various neutrals like creams, grays and browns. Furniture features curved sides and legs to contribute to a delicate graceful ambiance. Decorative patterns are used on everything from fabrics to wood, and include leaves, flowers or shells. These patterns are often mixed and matched throughout the home on upholstered chairs, walls and rugs. Elaborate motifs featuring dragons and other mythological creatures were often included. Chandeliers are commonly used light fixtures because they bring a bit of glamour into the interior of living rooms, bathrooms and bedrooms. Doorways and windows are typically arched, which can help soften up the look of a space.`,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Interior/J 44 Beet El Watan/02-1-mobile.webp'),
      banner: require('../images/projects/Interior/J 44 Beet El Watan/05-1.webp'),
      imgs: [
        require('../images/projects/Interior/J 44 Beet El Watan/02-1.webp'),
        require('../images/projects/Interior/J 44 Beet El Watan/05-1.webp'),
        require('../images/projects/Interior/J 44 Beet El Watan/03-1.webp')
      ]
    },
    filter: 'cat-2',
    colWidth: 'col-lg-3'
  },
  {
    id: 54,
    title: 'Lungern',
    description: {
      par1: `Inspired by French art and design from the 1920s, art deco (short for arts decoratifs) is characterized by sleek, metallic finishes like stainless steel, glass and chrome. Unlike Vintage style, which highlights floral patterns, rugs and linens feature graphic prints such as zig zags, sunbursts , Furniture and wall art throughout an art deco-inspired home are defined by jagged, pointed edges. Pieces are also larger armoires, dressers and couches are all typical of the 1920s. Light fixtures are just as structured as the decor and furniture. They’re typically made of sleek, shiny materials like polished bronze, chrome or steel. Bold geometric patterns are frequently printed on wall sconces, ceiling mounts and lamps. Other angular designs like chevron and zig zags adorn light fixtures. Great examples of Art Deco architecture can be found in the historic district in Miami Beach, Florida where this whimsical style is embraced by many small hotels built in the mid-1920s to early 1940s`,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Interior/Lungern/b2-entranc-mobile.webp'),
      banner: require('../images/projects/Interior/Lungern/lungern-plazaA-03.webp'),
      imgs: [
        require('../images/projects/Interior/Lungern/b2-entranc.webp'),
        require('../images/projects/Interior/Lungern/lungern-plazaA-03.webp'),
        require('../images/projects/Interior/Lungern/lungern-plazaA-04.webp'),
        require('../images/projects/Interior/Lungern/lungern-plazaA-01.webp'),
        require('../images/projects/Interior/Lungern/lungern-plazaA-02.webp')
      ]
    },
    filter: 'cat-2',
    colWidth: 'col-lg-3'
  },
  {
    id: 55,
    title: 'MetroPolis Mini Compound',
    description: {
      par1: `European interior design features a charming mix of Traditionl and Contemporary styles. You’ll typically see flowing curves and lines on everything from chairs and couches to tables and sinks. Cabinets, however, have smooth, flat surfaces with little to no hardware. European color schemes are always subdued and understated, focusing on neutrals. Instead the attention is put on natural materials such as wood, stone and granite.`,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Interior/MetroPolis Mini Compound/plaza-1-mobile.webp'),
      banner: require('../images/projects/Interior/MetroPolis Mini Compound/entrance-mini-compound-new.webp'),
      imgs: [
        require('../images/projects/Interior/MetroPolis Mini Compound/plaza-1.webp'),
        require('../images/projects/Interior/MetroPolis Mini Compound/entrance-mini-compound-new.webp'),
        require('../images/projects/Interior/MetroPolis Mini Compound/plaza-2.webp'),
        require('../images/projects/Interior/MetroPolis Mini Compound/plaza-3.webp')
      ]
    },
    filter: 'cat-2',
    colWidth: 'col-lg-3'
  },
  {
    id: 56,
    title: '98 Beet El Watan',
    description: {
      par1: `Inspired by French art and design from the 1920s, art deco (short for arts decoratifs) is characterized by sleek, metallic finishes like stainless steel, glass and chrome. Unlike Vintage style, which highlights floral patterns, rugs and linens feature graphic prints such as zig zags, sunbursts , Furniture and wall art throughout an art deco-inspired home are defined by jagged, pointed edges. Pieces are also larger armoires, dressers and couches are all typical of the 1920s. Light fixtures are just as structured as the decor and furniture. They’re typically made of sleek, shiny materials like polished bronze, chrome or steel. Bold geometric patterns are frequently printed on wall sconces, ceiling mounts and lamps. Other angular designs like chevron and zig zags adorn light fixtures. Great examples of Art Deco architecture can be found in the historic district in Miami Beach, Florida where this whimsical style is embraced by many small hotels built in the mid-1920s to early 1940s.`,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Interior/98 Beet El Watan/plaza-1_2-mobile.webp'),
      banner: require('../images/projects/Interior/98 Beet El Watan/plaza-2_2.webp'),
      imgs: [
        require('../images/projects/Interior/98 Beet El Watan/plaza-1_2.webp'),
        require('../images/projects/Interior/98 Beet El Watan/plaza-2_2.webp'),
        require('../images/projects/Interior/98 Beet El Watan/plaza-4.webp'),
        require('../images/projects/Interior/98 Beet El Watan/plaza-5.webp')
      ]
    },
    filter: 'cat-2',
    colWidth: 'col-lg-3'
  },
  {
    id: 57,
    title: '105 Beet El Watan',
    description: {
      par1: `Greek interiors boast a subtle mediterranean feel, thanks to the use of warm beiges and deep blues. Furniture is typically made up of cast iron, wood and wrought-iron.`,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Interior/105 Beet El Watan/master-shot-2-mobile.webp'),
      banner: require('../images/projects/Interior/105 Beet El Watan/master-shot-1.webp'),
      imgs: [
        require('../images/projects/Interior/105 Beet El Watan/master-shot-2.webp'),
        require('../images/projects/Interior/105 Beet El Watan/master-shot-1.webp')
      ]
    },
    filter: 'cat-2',
    colWidth: 'col-lg-3'
  },
  {
    id: 58,
    title: '3d Plans',
    description: {
      par1: ``,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Interior/3D Plans/Master-Typicle-mobile.webp'),
      banner: require('../images/projects/Interior/3D Plans/pinta-house-left.webp'),
      imgs: [
        require('../images/projects/Interior/3D Plans/Master-Typicle.webp'),
        require('../images/projects/Interior/3D Plans/pinta-house-left.webp'),
        require('../images/projects/Interior/3D Plans/1-5.webp'),
        require('../images/projects/Interior/3D Plans/1-Front-Ground.webp'),
        require('../images/projects/Interior/3D Plans/1st-Left.webp'),
        require('../images/projects/Interior/3D Plans/4-C.webp'),
        require('../images/projects/Interior/3D Plans/Babel-1-Right_2.webp'),
        require('../images/projects/Interior/3D Plans/babel-1-right.webp'),
        require('../images/projects/Interior/3D Plans/babel-2-right.webp'),
        require('../images/projects/Interior/3D Plans/douplex-up.webp'),
        require('../images/projects/Interior/3D Plans/Ground-Back.webp'),
        require('../images/projects/Interior/3D Plans/Ground-Left.webp'),
        require('../images/projects/Interior/3D Plans/ground-right.webp'),
        require('../images/projects/Interior/3D Plans/Ground-Total.webp'),
        require('../images/projects/Interior/3D Plans/typical-right-pinta-house.webp')
      ]
    },
    filter: 'cat-2',
    colWidth: 'col-lg-3'
  },
  {
    id: 59,
    title: 'Qatar Private Villa',
    description: {
      par1: `Commonwealth or Cromwellian home design focuses on the basics – furniture comes with severe, straight lines with no intricate carvings or embellishments. Commonwealth shares similar characteristics to Bauhause style in that it concentrates on function rather than style and decor. Instead of velvet or silk upholstery, leather is used for its sleek and simple feel. The backs of seating were never stuffed, as this was considered far too luxurious for this prim and proper English style. Rather, classic open slat chair backs were used for their understated look. Light fixtures also feature very basic shapes with little detailing, like smooth geometrically shaped hanging pendants or simple track lighting that blends in with the walls.`,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Interior/Qatar Private Villa/4-2-mobile.webp'),
      banner: require('../images/projects/Interior/Qatar Private Villa/1-3.webp'),
      imgs: [
        require('../images/projects/Interior/Qatar Private Villa/4-2.webp'),
        require('../images/projects/Interior/Qatar Private Villa/1-3.webp'),
        require('../images/projects/Interior/Qatar Private Villa/1-F-1.webp'),
        require('../images/projects/Interior/Qatar Private Villa/2-F-1.webp'),
        require('../images/projects/Interior/Qatar Private Villa/3-3.webp'),
        require('../images/projects/Interior/Qatar Private Villa/5-1.webp'),
        require('../images/projects/Interior/Qatar Private Villa/6-.webp'),
        require('../images/projects/Interior/Qatar Private Villa/7.webp'),
        require('../images/projects/Interior/Qatar Private Villa/F5.webp'),
        require('../images/projects/Interior/Qatar Private Villa/F6.webp'),
        require('../images/projects/Interior/Qatar Private Villa/F7.webp'),
        require('../images/projects/Interior/Qatar Private Villa/F8.webp'),
        require('../images/projects/Interior/Qatar Private Villa/F9.webp'),
        require('../images/projects/Interior/Qatar Private Villa/Top-3.webp')
      ]
    },
    filter: 'cat-2',
    colWidth: 'col-lg-3'
  },
  {
    id: 60,
    title: 'Utopia Office Design',
    description: {
      par1: `Mid-century modern interiors can be seen throughout AMC’s hit TV show “Mad Men,” as the design era came to life during the 1940s-70s. Mid-century modern homes have a seamless flow from the inside to the outside thanks to sliding doors, picture windows, skylights and patios. Windows are often left bare to emphasize outdoor views. The furniture in is entirely unique, featuring statement pieces like marshmallow sofas and egg chairs. Many pieces, such as the Noguchi table, are asymmetrical and feature amorphous components, which make them feel more like sculptures than pieces of furniture [Attfield 1997]. Elements like floors and ceiling beams are stripped down to their original form, while walls are dressed up with graphic wallpaper featuring bold geometric patterns.`,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Interior/Utopia Office Design/1-4-mobile.webp'),
      banner: require('../images/projects/Interior/Utopia Office Design/3-4.webp'),
      imgs: [
        require('../images/projects/Interior/Utopia Office Design/1-4.webp'),
        require('../images/projects/Interior/Utopia Office Design/3-4.webp'),
        require('../images/projects/Interior/Utopia Office Design/2-2.webp'),
        require('../images/projects/Interior/Utopia Office Design/4-3.webp'),
        require('../images/projects/Interior/Utopia Office Design/5-2.webp'),
        require('../images/projects/Interior/Utopia Office Design/6-1.webp'),
        require('../images/projects/Interior/Utopia Office Design/7-1.webp'),
        require('../images/projects/Interior/Utopia Office Design/8.webp')
      ]
    },
    filter: 'cat-2',
    colWidth: 'col-lg-3'
  },
  {
    id: 61,
    title: 'Oscar Office Design',
    description: {
      par1: `Inspired by the decor prevalent during the colonization of Africa, Asia and the West Indies, British Colonial interiors feature lightweight cotton fabric on drapes, curtains and bedding. The most common color palette includes a mix of whites, beiges and browns to produce a subdued and subtle look. Flowers and plants are consistently used throughout the home to provide a fresh feel. Furniture is made up of materials like wicker, ebony, teak and mahogany. Tables, chairs, cabinets and sofas are usually constructed with dark-colored wood to create a rich, sophisticated ambiance. The deep-colored furniture provides a lovely contrast to the neutral-toned walls. Bell jar lanterns and lighted ceiling fans are popular fixtures to illuminate British Colonial homes.`,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Interior/Oscar Office Design/1-2-mobile.webp'),
      banner: require('../images/projects/Interior/Oscar Office Design/4-1.webp'),
      imgs: [
        require('../images/projects/Interior/Oscar Office Design/1-2.webp'),
        require('../images/projects/Interior/Oscar Office Design/4-1.webp'),
        require('../images/projects/Interior/Oscar Office Design/3-2.webp'),
        require('../images/projects/Interior/Oscar Office Design/5.webp')
      ]
    },
    filter: 'cat-2',
    colWidth: 'col-lg-3'
  },
  {
    id: 62,
    title: 'Archrete Luxury Entrances',
    description: {
      par1: `More and more of luxury design style entrances powered by 3d-max & v-ray.`,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Interior/Archrete Luxury Enterances/F-1-mobile.webp'),
      banner: require('../images/projects/Interior/Archrete Luxury Enterances/Entrance-C-50.webp'),
      imgs: [
        require('../images/projects/Interior/Archrete Luxury Enterances/F-1.webp'),
        require('../images/projects/Interior/Archrete Luxury Enterances/Entrance-C-50.webp'),
        require('../images/projects/Interior/Archrete Luxury Enterances/01-2.webp')
      ]
    },
    filter: 'cat-2',
    colWidth: 'col-lg-3'
  },
  {
    id: 63,
    title: 'Mountain View Villa Design',
    description: {
      par1: `Minimalist homes are free of any clutter, only displaying items like couches, tables and light fixtures that are essential for functional living. Minimalist interiors have a focus on simplicity and effortless beauty. Character is brought forth not through detail, but through natural materials and essential items. Rooms are monochromatic, usually featuring subdued neutrals. Walls are kept simple with only one or two decorative pieces, like a canvas or a mirror. All surfaces, from the kitchen to the bathroom should be completely cleared off. Furniture features clean lines with very little detail, and they often double as storage, like ottomans that open up to reveal space.`,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Interior/Mountain View Villa Design/001-mobile.webp'),
      banner: require('../images/projects/Interior/Mountain View Villa Design/02-1.webp'),
      imgs: [
        require('../images/projects/Interior/Mountain View Villa Design/001.webp'),
        require('../images/projects/Interior/Mountain View Villa Design/02-1.webp'),
        require('../images/projects/Interior/Mountain View Villa Design/03-1.webp'),
        require('../images/projects/Interior/Mountain View Villa Design/04-1.webp'),
        require('../images/projects/Interior/Mountain View Villa Design/Boy-Bedroom-1.webp'),
        require('../images/projects/Interior/Mountain View Villa Design/Boy-Bedroom-2.webp'),
        require('../images/projects/Interior/Mountain View Villa Design/Boy-Bedroom-3.webp'),
        require('../images/projects/Interior/Mountain View Villa Design/Girls-Bed1.webp'),
        require('../images/projects/Interior/Mountain View Villa Design/Girls-Bed2.webp'),
        require('../images/projects/Interior/Mountain View Villa Design/Girls-Bed3.webp'),
        require('../images/projects/Interior/Mountain View Villa Design/Girls-Bed4.webp'),
        require('../images/projects/Interior/Mountain View Villa Design/Living-1.webp'),
        require('../images/projects/Interior/Mountain View Villa Design/Living-3.webp'),
        require('../images/projects/Interior/Mountain View Villa Design/Living-4.webp'),
        require('../images/projects/Interior/Mountain View Villa Design/Living2.webp'),
        require('../images/projects/Interior/Mountain View Villa Design/Master-1.webp'),
        require('../images/projects/Interior/Mountain View Villa Design/Master-2.webp'),
        require('../images/projects/Interior/Mountain View Villa Design/Master-3.webp')
      ]
    },
    filter: 'cat-2',
    colWidth: 'col-lg-3'
  },
  {
    id: 64,
    title: 'Up Turn Entrance',
    description: {
      par1: `Warm colors, rugged textures and rustic finishes are all included in artisan home design. Materials and finishes are all of high quality and embraced for their craftsmanship and detailing.`,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Interior/Up Turn Enterance/1-1-mobile.webp'),
      banner: require('../images/projects/Interior/Up Turn Enterance/2-1.webp'),
      imgs: [
        require('../images/projects/Interior/Up Turn Enterance/1-1.webp'),
        require('../images/projects/Interior/Up Turn Enterance/2-1.webp'),
        require('../images/projects/Interior/Up Turn Enterance/3-1.webp')
      ]
    },
    filter: 'cat-2',
    colWidth: 'col-lg-3'
  },
  {
    id: 65,
    title: 'High Point Entrance',
    description: {
      par1: `This early American style combines characteristics from traditional and rustic home design, while incorporating historically inspired elements. The aesthetic draws on styles and trends from the 1600s to the 1800s, and often contains subdued, muted colors, and vintage finishes like oil-rubbed bronze. Fabrics and wallpaper feature a toile design, which was popular during the colonial era. Furniture is handmade and inspired by the early American frontier. Each table and chair goes through a custom-made process in which intricate carvings and design elements like flourishes are imprinted. Woods typically used to construct furniture includes maple, elm, hickory and cherry. Brass knobs and handles are added to cabinets, drawers and armoires for an added touch of sophistication. Handcrafted brass and copper lanterns, chandeliers and wall sconces give an old world feel to American Colonial interiors.`,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Interior/High Point Enterance/View-1-mobile.webp'),
      banner: require('../images/projects/Interior/High Point Enterance/Entrance-2.webp'),
      imgs: [
        require('../images/projects/Interior/High Point Enterance/View-1.webp'),
        require('../images/projects/Interior/High Point Enterance/Entrance-2.webp'),
        require('../images/projects/Interior/High Point Enterance/Entrance-1.webp'),
        require('../images/projects/Interior/High Point Enterance/View-1-.webp'),
        require('../images/projects/Interior/High Point Enterance/View-2-.webp'),
        require('../images/projects/Interior/High Point Enterance/View-2.webp'),
        require('../images/projects/Interior/High Point Enterance/View-3.webp'),
        require('../images/projects/Interior/High Point Enterance/View-4.webp')
      ]
    },
    filter: 'cat-2',
    colWidth: 'col-lg-3'
  },
  {
    id: 66,
    title: 'Mirage Entrance',
    description: {
      par1: `Cottage style can be described as cozy and comforting. Much like coastal design, cottage interiors evoke a light and airy feel. Instead of heavy drapes, lightweight fabrics such as lace and cotton are used. Weathered woods, distressed paints and a color palette of whites and creams come together to create a warm and welcoming informal home. Vintage fixtures and accessories such as chandeliers, farmhouse sinks and ornate rugs are used to produce a charming house aesthetic. Furnishings are comfortable and casual. Chairs and sofas can be dressed in lively pastel hues that reflect the sea and sky. Woven rugs sit underneath furniture to keep with the relaxed nature of the home. Beaded board and wood planks add character to walls, ceilings and floors. Natural light is accentuated to illuminate the home, while fixtures such as vintage chandeliers and weathered pendants provide additional light.`,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Interior/Mirage Enterance/3-Rakha-mobile.webp'),
      banner: require('../images/projects/Interior/Mirage Enterance/2-Rakha.webp'),
      imgs: [
        require('../images/projects/Interior/Mirage Enterance/3-Rakha.webp'),
        require('../images/projects/Interior/Mirage Enterance/2-Rakha.webp'),
        require('../images/projects/Interior/Mirage Enterance/1-Rakha.webp')
      ]
    },
    filter: 'cat-2',
    colWidth: 'col-lg-3'
  },
  {
    id: 67,
    title: 'Ronza Entrance',
    description: {
      par1: `Chrome and stainless steel make up a big part of modern design. Doing away with traditional metal details (like wrought iron) opens up the door for clean, polished metals to be used in their place.`,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Interior/Ronza Enterance/1-F-mobile.webp'),
      banner: require('../images/projects/Interior/Ronza Enterance/2-F.webp'),
      imgs: [
        require('../images/projects/Interior/Ronza Enterance/1-F.webp'),
        require('../images/projects/Interior/Ronza Enterance/2-F.webp'),
        require('../images/projects/Interior/Ronza Enterance/3-F.webp')
      ]
    },
    filter: 'cat-2',
    colWidth: 'col-lg-3'
  },
  {
    id: 68,
    title: 'Okas Entrance',
    description: {
      par1: `abstract home design focuses on unique architectural elements, such as asymmetrical doorways and home furnishings. It’s similar to modern design in that it utilizes clean lines, though abstract style also incorporates chaotic aspects to offer a fresh and unique perspective. This type of interior design lends itself to bold colors, such as yellows, blues and oranges. Black is used as a contrasting element to create a striking statement. Tables, chairs and sofas have clean lines, but much like the entirety of the abstract style, these furniture pieces are asymmetrical. Lighting also features simple lines and borrows from a modern aesthetic. You might see a geometric diamond-shaped pendant that casts abstract lighting throughout the interior. Powerd by 3d-max & V-ray`,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Interior/Okas Enterance/2-N-okas-mobile.webp'),
      banner: require('../images/projects/Interior/Okas Enterance/1-N-okas.webp'),
      imgs: [
        require('../images/projects/Interior/Okas Enterance/2-N-okas.webp'),
        require('../images/projects/Interior/Okas Enterance/1-N-okas.webp'),
        require('../images/projects/Interior/Okas Enterance/3-N-okas.webp')
      ]
    },
    filter: 'cat-2',
    colWidth: 'col-lg-3'
  },
  {
    id: 69,
    title: 'El Attal Entrance',
    description: {
      par1: `Modernism was a rejection of the ornate flourishes of other design styles, such as Gothic, Renaissance and Victorian styles of design. Therefore, many of the different modern designs have several common components of which the design themes have become associated with modern design.`,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Interior/El Attal Enterance/1-Attal-mobile.webp'),
      banner: require('../images/projects/Interior/El Attal Enterance/2-Attal.webp'),
      imgs: [
        require('../images/projects/Interior/El Attal Enterance/1-Attal.webp'),
        require('../images/projects/Interior/El Attal Enterance/2-Attal.webp')
      ]
    },
    filter: 'cat-2',
    colWidth: 'col-lg-3'
  },
  {
    id: 70,
    title: 'Head Quarter Office',
    description: {
      par1: `The Chippendale interior design style emerged in 1754 when Thomas Chippendale published the furniture catalog “The Gentleman and Cabinet Maker’s Director.” Chippendale furniture features influences from Gothic and Rococo furnishings and Chinese architectural elements such as roofs and window patterns [Yingquan, Lei & Qi 2013ref]. The back legs of furniture flow upward to form intricately detailed backrests of chairs. Other characteristics of Chippendale furniture include tapered legs, club feet and lacy patterns. Furnishings are typically made of mahogany, a material which is easily carved and stands up to everyday wear and tear. Stools and chairs are upholstered with fine fabrics like velvet or silk. Chippendale style interiors are filled with neutral tones like browns, creamy whites and grays. Metallics such as gold, silver and bronze are also used throughout the home. Chandeliers are a common light fixture used to create a sense of sophistication.`,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Interior/Head Quarter Office/01-1-mobile.webp'),
      banner: require('../images/projects/Interior/Head Quarter Office/03.webp'),
      imgs: [
        require('../images/projects/Interior/Head Quarter Office/01-1.webp'),
        require('../images/projects/Interior/Head Quarter Office/03.webp'),
        require('../images/projects/Interior/Head Quarter Office/1-outdoor.webp'),
        require('../images/projects/Interior/Head Quarter Office/2-outdoor.webp'),
        require('../images/projects/Interior/Head Quarter Office/02.webp'),
        require('../images/projects/Interior/Head Quarter Office/3-outdoor.webp'),
        require('../images/projects/Interior/Head Quarter Office/4-outdoor.webp'),
        require('../images/projects/Interior/Head Quarter Office/04.webp'),
        require('../images/projects/Interior/Head Quarter Office/05.webp')
      ]
    },
    filter: 'cat-2',
    colWidth: 'col-lg-3'
  },
  {
    id: 71,
    title: 'Assema Interior Design',
    description: {
      par1: `This mid-century style combines modernity with idealistic minimalism. Unlike angular art deco , art modern (a.k.a. streamline modern) designs are tapered, rounded and have a more horizontal emphasis. Frequently, they are adorned with parallel “flow” or “speed” lines [Cogdell 2010]. Art Modern homes feature an all-white color palette, and relies on smooth surfaces with little decorative detailing. Doorways, windows and other architectural elements come with curved corners. Raw, natural textures such as metal, concrete and glass are all mixed and matched throughout the home as well. The key to art modern is simplicity – decorative patterns are scarce. Instead, solid neutrals really help to make a strong statement. Furniture is stripped down and typically emulates a sense of motion with pieces like tiered tables. Chairs and sofas also have rounded edges and use contrasting material colors like black and white. Surfaces are glossy to give the furniture a modern feel. Floor and table lamps are popular light fixtures in the art modern style. These lamps commonly feature frosted glass and shades are circular and also made of glass. `,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Interior/Assema Interior Design/5--mobile.webp'),
      banner: require('../images/projects/Interior/Assema Interior Design/4-.webp'),
      imgs: [
        require('../images/projects/Interior/Assema Interior Design/5-.webp'),
        require('../images/projects/Interior/Assema Interior Design/4-.webp'),
        require('../images/projects/Interior/Assema Interior Design/1-.webp'),
        require('../images/projects/Interior/Assema Interior Design/2-.webp'),
        require('../images/projects/Interior/Assema Interior Design/3-.webp'),
        require('../images/projects/Interior/Assema Interior Design/6.webp'),
        require('../images/projects/Interior/Assema Interior Design/cam-1.webp'),
        require('../images/projects/Interior/Assema Interior Design/cam-01.webp'),
        require('../images/projects/Interior/Assema Interior Design/cam-2.webp'),
        require('../images/projects/Interior/Assema Interior Design/cam-02.webp'),
        require('../images/projects/Interior/Assema Interior Design/cam-3.webp'),
        require('../images/projects/Interior/Assema Interior Design/cam-03.webp')
      ]
    },
    filter: 'cat-2',
    colWidth: 'col-lg-3'
  },
  {
    id: 72,
    title: 'Eight Office',
    description: {
      par1: `Arts & Crafts design borrows interior elements from a number of different styles, including art nouvao and artisian. This design movement arose in response to the mass-production of global industrialization. It celebrated the nobility of the traditional craftsman, who imbued each piece with uniqueness and a humanity absent in mechanized production. In contrast to historical revival styles, furniture in the Arts & Crafts aesthetic tend to embrace a more stern, straightforward form [Rodel 2003]. Handcrafted furniture, rich wood trim made of oak or mahogany, along with stained glass and ceramic backsplash tile are all items that make up this interior motif. Imbued with naturalism, this style has a color palette that is typically earth-inspired and include dusty oranges, browns, taupes and sage greens. Another key to Arts & Crafts style is built-in elements. Kitchen cabinets, bookcases, shelving, benches and light fixtures are typically built into the walls in an effort to blend well with the architecture and create unity throughout the home. Lighting is usually made up of warm tones like hammered copper or bronze.`,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Interior/Eight Office/1-mobile.webp'),
      banner: require('../images/projects/Interior/Eight Office/01.webp'),
      imgs: [
        require('../images/projects/Interior/Eight Office/1.webp'),
        require('../images/projects/Interior/Eight Office/01.webp'),
        require('../images/projects/Interior/Eight Office/2.webp'),
        require('../images/projects/Interior/Eight Office/3.webp'),
        require('../images/projects/Interior/Eight Office/4.webp'),
        require('../images/projects/Interior/Eight Office/022.webp'),
        require('../images/projects/Interior/Eight Office/033.webp')
      ]
    },
    filter: 'cat-2',
    colWidth: 'col-lg-3'
  },
  {
    id: 73,
    title: 'Okas',
    description: {
      par1: `Carolean style, otherwise referred to as restoration style, is similar to Baroque -influenced houses. Brass is the hardware of choice for pulls, faucets and door knobs. Frontal is delicately carved and features elegant woodwork inspired by floral and fruit motifs as well as baluster-style legs.`,
      par2: `Walnut is the type of wood most commonly used to construct chairs, tables and so forth. A decorative covering of fine wood known as veneer is applied to furniture to add character. Carolean lighting boasts a mix of modern and Old World characteristics thanks to simple silhouettes and detailed finishes. Table lamps feature shades made of silk or velvet, and they’re adorned with tapestries and fringe. This style was en vogue in England during the reign of Charles II.`
    },
    images: {
      thumb: require('../images/projects/Interior/Okas/1-1-mobile.webp'),
      banner: require('../images/projects/Interior/Okas/4-1.webp'),
      imgs: [
        require('../images/projects/Interior/Okas/1-1.webp'),
        require('../images/projects/Interior/Okas/4-1.webp'),
        require('../images/projects/Interior/Okas/2-1.webp'),
        require('../images/projects/Interior/Okas/3-1.webp'),
        require('../images/projects/Interior/Okas/5-1.webp'),
        require('../images/projects/Interior/Okas/7-1.webp')
      ]
    },
    filter: 'cat-2',
    colWidth: 'col-lg-3'
  },
  {
    id: 74,
    title: 'Vivienda',
    description: {
      par1: `Coastal style :  gives off a relaxed, subdued and carefree vibe. Beach-inspired elements such as sand dollars, sea glass and driftwood are used as decor throughout the home. A crisp white color palette is common in coastal style, with occasional splashes of blues, greens, aquas and corals. Light is a central element in coastal interior design, therefore, homes feature plenty of glass doors, skylights and windows. Coastal furniture typically comes with a lived-in, by-the-sea feel, so you’ll often see painted wood chairs and tables with a distressed finish or a natural woven wicker chair. Light fixtures also use natural elements to keep up the beachy ambiance. Chandeliers made up of cascading petals of sea glass or hanging wooden pendants that feature a worn away finish are both`,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Interior/Vivienda/1--mobile.webp'),
      banner: require('../images/projects/Interior/Vivienda/3-.webp'),
      imgs: [
        require('../images/projects/Interior/Vivienda/1-.webp'),
        require('../images/projects/Interior/Vivienda/3-.webp'),
        require('../images/projects/Interior/Vivienda/2-.webp'),
        require('../images/projects/Interior/Vivienda/4-.webp')
      ]
    },
    filter: 'cat-2',
    colWidth: 'col-lg-3'
  },
  {
    id: 75,
    title: 'Sei Rose',
    description: {
      par1: `Modern vs. Contemporary: There are quite a few differences between these two design styles. A modern space tends to follow a strict style format while contemporary has many variations in its interiors.`,
      par2: `Modern in its true form is rarely viewed as stark or cold, while some contemporary interiors, including those that follow minimalist trends, celebrate a bold starkness.
      Modern pieces of decor tend to have a function, whereas contemporary pieces may concentrate more on the form itself.
      The color palette of modern design leans toward naturals or neutrals that have a warm, inviting feel. Contemporary often enjoys stark contrast with plenty of blacks and whites, though it has no problems swinging from one extreme to the other on the color scale.
      Modern design favors strong lines, while the contemporary style loves curves.`
    },
    images: {
      thumb: require('../images/projects/Interior/Sei Rose/01-1-mobile.webp'),
      banner: require('../images/projects/Interior/Sei Rose/04.webp'),
      imgs: [
        require('../images/projects/Interior/Sei Rose/01-1.webp'),
        require('../images/projects/Interior/Sei Rose/04.webp'),
        require('../images/projects/Interior/Sei Rose/02-1.webp'),
        require('../images/projects/Interior/Sei Rose/06.webp')
      ]
    },
    filter: 'cat-2',
    colWidth: 'col-lg-6'
  },
  {
    id: 76,
    title: 'Home Town Premium 3',
    description: {
      par1: `The Modern Style is the design and decor of the modernism movement, which began in the very late 1800s. Birthed by the German Bauhaus schools of design and the Scandinavian design emphasis on simplicity and function, the modern decor style is very old. In general terms, modern decor is linked to the beginning through the middle of the 20th century.`,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Interior/Home Town Premium 3/01-mobile.webp'),
      banner: require('../images/projects/Interior/Home Town Premium 3/03.webp'),
      imgs: [
        require('../images/projects/Interior/Home Town Premium 3/01.webp'),
        require('../images/projects/Interior/Home Town Premium 3/03.webp'),
        require('../images/projects/Interior/Home Town Premium 3/02.webp')
      ]
    },
    filter: 'cat-2',
    colWidth: 'col-lg-3'
  },
  {
    id: 77,
    title: 'Elizabeth',
    description: {
      par1: `Much like Modern design, German-born Bauhaus style puts an emphasis on simplicity that is evoked through clean, sharp lines. Functionality, a key element of this interior motif, was incorporated in concert with an appreciation of biological science. This ecological design was a unification of art and science, with satisfying human needs as the focal point. For example, light fixtures are chosen based on their style as well as how they satisfy the function of a room (i.e. under-cabinet lighting for prep work in the kitchen). You’ll see a lot of simple, built-in light fixtures to continue with the sleekness of a room. On the whole, Bauhaus furniture is incredibly flexible in its ability to adapt to the individual, their unique environment and needs. Many furnishings and lighting can be collapsed, folded or adjusted to the specialized requirements of each situation [Droste 2002]. Bauhaus designs are understated and feature smooth lines with a mix of organic materials such as leather, glass and laminated wood.`,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Interior/Elizabeth/2-3-mobile.webp'),
      banner: require('../images/projects/Interior/Elizabeth/1-2.webp'),
      imgs: [
        require('../images/projects/Interior/Elizabeth/2-3.webp'),
        require('../images/projects/Interior/Elizabeth/1-2.webp'),
        require('../images/projects/Interior/Elizabeth/3-3.webp')
      ]
    },
    filter: 'cat-2',
    colWidth: 'col-lg-3'
  },
  {
    id: 78,
    title: 'Beet El Watan 2',
    description: {
      par1: `Tudor Design Style: This architectural style represents the last evolution of Medieval design. The low Tudor arch is a defining characteristic of this classic English period. Tudor homes typically have stone or stucco on their exterior with wooden front doors and Windows to recreate an English style.`,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Exterior/Beet El Watan 2/1--mobile.webp'),
      banner: require('../images/projects/Exterior/Beet El Watan 2/1-Edit.webp'),
      imgs: [
        require('../images/projects/Exterior/Beet El Watan 2/1-.webp'),
        require('../images/projects/Exterior/Beet El Watan 2/1-Edit.webp')
      ]
    },
    filter: 'cat-1',
    colWidth: 'col-lg-3'
  },
  {
    id: 79,
    title: 'Beet El Watan',
    description: {
      par1: `This mid-century style combines modernity with idealistic minimalism. Unlike angular art deco, art moderne (a.k.a. streamline moderne) designs are tapered, rounded and have a more horizontal emphasis. Frequently, they are adorned with parallel “flow” or “speed” lines [Cogdell 2010]. Art Moderne homes feature an all-white color palette and relies on smooth surfaces with little decorative detailing. Doorways, windows and other architectural elements come with curved corners. Raw, natural textures such as metal, terra cotta tile, concrete and glass are all mixed and matched throughout the home as well.`,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Exterior/Beet El Watan/5-Night-mobile.webp'),
      banner: require('../images/projects/Exterior/Beet El Watan/E-3.webp'),
      imgs: [
        require('../images/projects/Exterior/Beet El Watan/5-Night.webp'),
        require('../images/projects/Exterior/Beet El Watan/E-3.webp'),
        require('../images/projects/Exterior/Beet El Watan/E-01.webp')
      ]
    },
    filter: 'cat-1',
    colWidth: 'col-lg-3'
  },
  {
    id: 80,
    title: 'H1 & H2 Mini Compound',
    description: {
      par1: `Arabian home design exudes luxury and elegance by combining lush materials, bold colors like turquoise, and ornate patterns. Mixing and matching various textures and materials such as metal light fixtures and glass decor, produces a charming and enticing effects. Mosaic patterns are frequently implemented into area rugs, and curtains to create a decorative appearance.`,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Exterior/H1 & H2 Mini Compound/F-1-mobile.webp'),
      banner: require('../images/projects/Exterior/H1 & H2 Mini Compound/F-4.webp'),
      imgs: [
        require('../images/projects/Exterior/H1 & H2 Mini Compound/F-1.webp'),
        require('../images/projects/Exterior/H1 & H2 Mini Compound/F-4.webp'),
        require('../images/projects/Exterior/H1 & H2 Mini Compound/F-2.webp'),
        require('../images/projects/Exterior/H1 & H2 Mini Compound/F-3.webp')
      ]
    },
    filter: 'cat-1',
    colWidth: 'col-lg-3'
  },
  {
    id: 81,
    title: 'Eshraqa Villa',
    description: {
      par1: `Russian home style can be defined as snug, cozy and welcoming. It blends both classic and country interiors for the perfect mix of luxury and simplicity. These spaces are filled with meaningful pieces like handmade wall decorations and handcrafted furniture. Colors used throughout Russian homes include inviting hues like mint greens, creamy whites, golden yellows and deep browns. Flowers are an oft-used decorating tool – floral patterns are found on curtains and pillows, floral paintings are hung on the walls, and blooms in vases add charm and brightness into the home.`,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Exterior/Eshraqa Villa/Final-Day-mobile.webp'),
      banner: require('../images/projects/Exterior/Eshraqa Villa/1-Edit.webp'),
      imgs: [
        require('../images/projects/Exterior/Eshraqa Villa/Final-Day.webp'),
        require('../images/projects/Exterior/Eshraqa Villa/1-Edit.webp'),
        require('../images/projects/Exterior/Eshraqa Villa/Elevation-2.webp')
      ]
    },
    filter: 'cat-1',
    colWidth: 'col-lg-3'
  },
  {
    id: 82,
    title: 'N 53 Beet El Watan',
    description: {
      par1: `The Revival period in nineteenth century American design Style and architecture was a direct result of the rapidly changing country. In reaction to growing urbanization, mechanization and demographic changes, many Americans pined for simpler times and romanticized the past. The result was a revival of many design styles from antiquity, which were thought to reflect the values of the homeowner and their family. Furniture, lighting and architectural elements drew inspiration from Greek, Geothic, Egyptian, Spanish and Rocco styles to name a few. Today, we see the Revivalist spirit in the detailing, materials and color palettes of rooms and signature pieces, in concert with more eclectic and modern design tastes.`,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Exterior/N 53 Beet El Watan/n-53-shot-02-mobile.webp'),
      banner: require('../images/projects/Exterior/N 53 Beet El Watan/n-53-shot-01.webp'),
      imgs: [
        require('../images/projects/Exterior/N 53 Beet El Watan/n-53-shot-02.webp'),
        require('../images/projects/Exterior/N 53 Beet El Watan/n-53-shot-01.webp'),
        require('../images/projects/Exterior/N 53 Beet El Watan/n-53-shot-1.webp'),
        require('../images/projects/Exterior/N 53 Beet El Watan/n-53-shot-2.webp')
      ]
    },
    filter: 'cat-1',
    colWidth: 'col-lg-3'
  },
  {
    id: 83,
    title: 'MetroPolis Mini Compound',
    description: {
      par1: `European interior design features a charming mix of Traditionl and Contemporary styles. You’ll typically see flowing curves and lines on everything from chairs and couches to tables and sinks. Cabinets, however, have smooth, flat surfaces with little to no hardware. European color schemes are always subdued and understated, focusing on neutrals. Instead the attention is put on natural materials such as wood, stone and granite.`,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Exterior/MetroPolis Mini Compound/01-mobile.webp'),
      banner: require('../images/projects/Exterior/MetroPolis Mini Compound/02.webp'),
      imgs: [
        require('../images/projects/Exterior/MetroPolis Mini Compound/01.webp'),
        require('../images/projects/Exterior/MetroPolis Mini Compound/02.webp'),
        require('../images/projects/Exterior/MetroPolis Mini Compound/03.webp'),
        require('../images/projects/Exterior/MetroPolis Mini Compound/04.webp'),
        require('../images/projects/Exterior/MetroPolis Mini Compound/05.webp'),
        require('../images/projects/Exterior/MetroPolis Mini Compound/plaza-3.webp')
      ]
    },
    filter: 'cat-1',
    colWidth: 'col-lg-3'
  },
  {
    id: 84,
    title: 'E 71 Beet El Watan',
    description: {
      par1: `Much like Greek Design, Mediterranean design is influenced by location. Touches of Spain, Greece and Italy can be found in Mediterranean homes, and these locations are brought to life through bright, bold colors influenced by the sea and sky, like turquoise, emerald and yellow. Decorative mosaic tile is often used on interior elements like floors, table tops, bullnose edged counters, mirrors and backsplashes to bring a charming rustic appeal to the home. Mediterranean furniture is built short and low to the ground.`,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Exterior/E 71 Beet El Watan/F-11-mobile.webp'),
      banner: require('../images/projects/Exterior/E 71 Beet El Watan/F2.webp'),
      imgs: [
        require('../images/projects/Exterior/E 71 Beet El Watan/F-11.webp'),
        require('../images/projects/Exterior/E 71 Beet El Watan/F-3-1.webp'),
        require('../images/projects/Exterior/E 71 Beet El Watan/F2.webp')
      ]
    },
    filter: 'cat-1',
    colWidth: 'col-lg-6'
  },
  {
    id: 85,
    title: 'D 102 Beet El Watan',
    description: {
      par1: `Mexican-inspired homes deliver a similar feel to eclectic interiors. Numerous bold colors are used, such as bright pinks, greens, oranges, reds and yellows. Candlesticks, ceramic pots, ironware and hand-sewn rugs also bring personality into a Mexican home. The pottery often has intricate and colorful designs influenced by the flora and fauna found in Mexico. Chairs, tables and sofas boast an elegant look, with influences of Spanish colonial and Tuscan style. Mexican furniture can either come with a refined, stained dark wood or weathered wood for a more casual, rustic beauty. Prints and patterns like mosaic can be found on everything from tiles to pillows.`,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Exterior/D 102 Beet El Watan/F-1-2-mobile.webp'),
      banner: require('../images/projects/Exterior/D 102 Beet El Watan/F-N.webp'),
      imgs: [
        require('../images/projects/Exterior/D 102 Beet El Watan/F-1-2.webp'),
        require('../images/projects/Exterior/D 102 Beet El Watan/F-N.webp'),
        require('../images/projects/Exterior/D 102 Beet El Watan/F-2-1.webp')
      ]
    },
    filter: 'cat-1',
    colWidth: 'col-lg-3'
  },
  {
    id: 86,
    title: 'B 67 Beet El Watan',
    description: {
      par1: `Much like Greek Design, Mediterranean design is influenced by location. Touches of Spain, Greece and Italy can be found in Mediterranean homes, and these locations are brought to life through bright, bold colors influenced by the sea and sky, like turquoise, emerald and yellow. Decorative mosaic tile is often used on interior elements like floors, table tops, bullnose edged counters, mirrors and backsplashes to bring a charming rustic appeal to the home. Mediterranean furniture is built short and low to the ground.`,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Exterior/B 67 Beet El Watan/F-1-1-mobile.webp'),
      banner: require('../images/projects/Exterior/B 67 Beet El Watan/F-3.webp'),
      imgs: [
        require('../images/projects/Exterior/B 67 Beet El Watan/F-1-1.webp'),
        require('../images/projects/Exterior/B 67 Beet El Watan/F-3.webp'),
        require('../images/projects/Exterior/B 67 Beet El Watan/F-2.webp')
      ]
    },
    filter: 'cat-1',
    colWidth: 'col-lg-3'
  },
  {
    id: 87,
    title: '710 El Andalus',
    description: {
      par1: `Northwestern Design Style : combine both Modern and Traditional styles. There is an emphasis on natural light and outside views, so large wall-to-wall windows, sliding glass doors and skylights are found throughout the home. A palette of cream tones warm the interior, while sparingly used bold hues like bright oranges and reds bring personality into the space. A variety of materials are used throughout each room, such as concrete, wood and glass for a combination of old and new. Lines found on tables, chairs and sofas are simple and clean, while upholstery features a variety of textures and patterns. Northwestern kitchens usually have espresso or coffee stations with a built-in shelf for mugs, cups and saucers – it makes sense that Starbucks originated in the Pacific Northwest.`,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Exterior/710 El Andalus/01-final-mobile.webp'),
      banner: require('../images/projects/Exterior/710 El Andalus/03-final.webp'),
      imgs: [
        require('../images/projects/Exterior/710 El Andalus/01-final.webp'),
        require('../images/projects/Exterior/710 El Andalus/03-final.webp'),
        require('../images/projects/Exterior/710 El Andalus/04-final.webp'),
        require('../images/projects/Exterior/710 El Andalus/02-final.webp'),
        require('../images/projects/Exterior/710 El Andalus/night-shot.webp')
      ]
    },
    filter: 'cat-1',
    colWidth: 'col-lg-3'
  },
  {
    id: 88,
    title: 'Home Town Premium 3',
    description: {
      par1: `The Modern Style is the design and decor of the modernism movement, which began in the very late 1800s. Birthed by the German Bauhaus schools of design and the Scandinavian design emphasis on simplicity and function, the modern decor style is very old. In general terms, modern decor is linked to the beginning through the middle of the 20th century.`,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Exterior/Home Town Premium 3/hometown-3-shot-4-mobile.webp'),
      banner: require('../images/projects/Exterior/Home Town Premium 3/hometown-3-shot-1.webp'),
      imgs: [
        require('../images/projects/Exterior/Home Town Premium 3/hometown-3-shot-4.webp'),
        require('../images/projects/Exterior/Home Town Premium 3/hometown-3-shot-1.webp'),
        require('../images/projects/Exterior/Home Town Premium 3/02.webp'),
        require('../images/projects/Exterior/Home Town Premium 3/03.webp'),
        require('../images/projects/Exterior/Home Town Premium 3/hometown-3-main-shot.webp'),
        require('../images/projects/Exterior/Home Town Premium 3/hometown-3-shot-2.webp')
      ]
    },
    filter: 'cat-1',
    colWidth: 'col-lg-3'
  },
  {
    id: 89,
    title: 'J 171 Beet El Watan',
    description: {
      par1: `The word “Modern” is one of the most misused phrases in Designing. Often confused with contemporary style, today’s Modern style is actually based on a design movement that was birthed around the turn of the 20th century.`,
      par2: `The modern style movement continued to grow in popularity throughout the first half of the century, and later derivatives of this style are known as midcentury modern and postmodern.`
    },
    images: {
      thumb: require('../images/projects/Exterior/J 171 Beet El Watan/final-j-171-bet-el-watan-shot-1-mobile.webp'),
      banner: require('../images/projects/Exterior/J 171 Beet El Watan/final-j-171-bet-el-watan-shot-3.webp'),
      imgs: [
        require('../images/projects/Exterior/J 171 Beet El Watan/final-j-171-bet-el-watan-shot-1.webp'),
        require('../images/projects/Exterior/J 171 Beet El Watan/final-j-171-bet-el-watan-shot-3.webp'),
        require('../images/projects/Exterior/J 171 Beet El Watan/final-j-171-bet-el-watan-shot-2.webp')
      ]
    },
    filter: 'cat-1',
    colWidth: 'col-lg-3'
  },
  {
    id: 90,
    title: 'Private Villa',
    description: {
      par1: ``,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Architecture Drawings/Private Villa/07-Model-12-mobile.webp'),
      banner: require('../images/projects/Architecture Drawings/Private Villa/shot-01.webp'),
      imgs: [
        require('../images/projects/Architecture Drawings/Private Villa/07-Model-12.webp'),
        require('../images/projects/Architecture Drawings/Private Villa/07-Model-1.webp'),
        require('../images/projects/Architecture Drawings/Private Villa/07-Model-2.webp'),
        require('../images/projects/Architecture Drawings/Private Villa/07-Model-15.webp'),
        require('../images/projects/Architecture Drawings/Private Villa/shot-01.webp')
      ]
    },
    filter: 'cat-5',
    colWidth: 'col-lg-3'
  },
  {
    id: 91,
    title: 'Up Turn',
    description: {
      par1: ``,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Architecture Drawings/Up Turn/UP-TURN-mobile.webp'),
      banner: require('../images/projects/Architecture Drawings/Up Turn/UP-TURN2.webp'),
      imgs: [
        require('../images/projects/Architecture Drawings/Up Turn/UP-TURN.webp'),
        require('../images/projects/Architecture Drawings/Up Turn/UP-TURN2.webp')
      ]
    },
    filter: 'cat-5',
    colWidth: 'col-lg-3'
  },
  {
    id: 92,
    title: 'Enterances',
    description: {
      par1: ``,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Architecture Drawings/Enterances/ENTRANCE-FINAL-Model-3-mobile.webp'),
      banner: require('../images/projects/Architecture Drawings/Enterances/View-1.webp'),
      imgs: [
        require('../images/projects/Architecture Drawings/Enterances/ENTRANCE-FINAL-Model-3.webp'),
        require('../images/projects/Architecture Drawings/Enterances/ENTRANCE-FINAL-Model-1.webp'),
        require('../images/projects/Architecture Drawings/Enterances/ENTRANCE-FINAL-Model-2.webp'),
        require('../images/projects/Architecture Drawings/Enterances/View-1.webp')
      ]
    },
    filter: 'cat-5',
    colWidth: 'col-lg-3'
  },
  {
    id: 93,
    title: 'A 112 Beet El Watan',
    description: {
      par1: ``,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Architecture Drawings/A 112 Beet El Watan/a-112-02-mobile.webp'),
      banner: require('../images/projects/Architecture Drawings/A 112 Beet El Watan/thumbnail_112-FInal.webp'),
      imgs: [
        require('../images/projects/Architecture Drawings/A 112 Beet El Watan/a-112-02.webp'),
        require('../images/projects/Architecture Drawings/A 112 Beet El Watan/a-112-plan.webp'),
        require('../images/projects/Architecture Drawings/A 112 Beet El Watan/A-112.webp'),
        require('../images/projects/Architecture Drawings/A 112 Beet El Watan/thumbnail_112-FInal.webp')
      ]
    },
    filter: 'cat-5',
    colWidth: 'col-lg-3'
  },
  {
    id: 94,
    title: '54 Beet El Watan',
    description: {
      par1: ``,
      par2: ``
    },
    images: {
      thumb: require('../images/projects/Architecture Drawings/54 Beet El Watan/02-2-mobile.webp'),
      banner: require('../images/projects/Architecture Drawings/54 Beet El Watan/04-2.webp'),
      imgs: [
        require('../images/projects/Architecture Drawings/54 Beet El Watan/02-2.webp'),
        require('../images/projects/Architecture Drawings/54 Beet El Watan/04-2.webp'),
        require('../images/projects/Architecture Drawings/54 Beet El Watan/01-4.webp'),
        require('../images/projects/Architecture Drawings/54 Beet El Watan/03-2.webp')
      ]
    },
    filter: 'cat-5',
    colWidth: 'col-lg-3'
  }
];

export const getProjectById = id =>
  projects.find(project => project.id === Number(id));
export const getAllProjects = () => projects;
export const getRelatedProjects = project =>
  projects
    .filter(p => p.id !== project.id && p.filter === project.filter)
    .slice(0, 6);
