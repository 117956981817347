import React from 'react';
import { Link } from 'react-router-dom';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import './imaketSection.css';
import { sampleVideo, thumbnail } from '../../assets';

const ImaketSection = () => {
  return (
    <section className="imaket-section">
      <h2 className="bold imaket-heading">I maket</h2>
      <div className="imaket-description">
        <h3>
          Reality in a <span className="gold">new modern way</span>
        </h3>
        <p>
          GREAT TOOL TO PRESENT ARCHITECTURAL PROJECTS RUN EYE-CATCHING
          PRESENTATIONS OF ARCHITECTURAL PROJECTS IMAKET IS AN ELEGANT SOLUTION
          TO SHOWCASE YOUR DEVELOPMENT IN A STUNNING LIGHT - SPICE UP THE 3D
          INTERACTIVE MODEL WITH AS MANY DETAILS AS YOU WISH. TO CREATE A FULL
          IMMERSION FOR THE SPECTATOR.
        </p>
        <Link to="/imaket" className="link-btn">
          learn more
        </Link>
      </div>
      <div className="imaket-video">
        <VideoPlayer source={sampleVideo} poster={thumbnail} muted autoPlay />
      </div>
    </section>
  );
};

export default ImaketSection;
