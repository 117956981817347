import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const members = [
  {
    image: require('../../images/testimonials/pic1.webp'),
    fullname: 'Radix',
    description:
      'بتطور شركتنا وتوسعها في السوق المصري فاحنا بنوسع حلقة شركاء النجاح ومنهم شركة Archrete اللي احنا شغالين معاهم على مدار 4 سنين في مشاريع داخل وخارج مصر, وفي مشاريع اتنفذت بالفعل على ارض المملكة العربية السعودية من تنفيذهم وان شاء الله هيكون مشاريع جدية وكبيرة جاية معاهم.'
  },
  {
    image: require('../../images/testimonials/pic2.webp'),
    fullname: 'Nile Development',
    description:
      'شركة Archrete هما اكتر شباب موهبين في العمارة وكانت من المفاجآت السارة جدا لينا ان Archrete فازت بالمسابقة المعمارية اللي مسجل فيها اكتر من 100 شركة منهم شركات عالمية وكانت المفجأة السارة ان اللي عمل افضل كتلة معمارية للمشروع كانوا شباب مصريين هما Archrete.'
  },
  {
    image: require('../../images/testimonials/pic3.webp'),
    fullname: 'United',
    description:
      'استعانت الشركة بواحدة من افضل شركات التصميمات الهندسية والانشاءية في مصر والعالم العربي وهي شركة Archrete اللي صممت المشروع على اسس هندسية وجدوى اقتصادية.'
  },
  {
    image: require('../../images/testimonials/pic4.png'),
    fullname: 'UC',
    description:
      'شكر خاص لشركة Archrete لانهم الداعم الاساسي لينا ومعانا من اول يوم في UC وهما شركاء النجاح.'
  }
];

var bgimg = require('../../images/background/ptn-1.png');

const Testimonials = () => {
  const options = {
    loop: true,
    autoplay: true,
    margin: 80,
    nav: false,
    dots: true,
    navText: [
      '<i class="fa fa-angle-left"></i>',
      '<i class="fa fa-angle-right"></i>'
    ],
    responsive: {
      0: {
        items: 1
      },
      991: {
        items: 2
      }
    }
  };
  return (
    <>
      <div
        className="section-full p-t140 bg-repeat tm-testimonial-wrap"
        style={{ backgroundImage: 'url(' + bgimg + ')' }}
      >
        <div className="container">
          <div className="section-content">
            {/* TITLE START */}
            <div className="section-head text-left">
              <h2 className="text-uppercase font-36">Testimonials</h2>
              <div className="wt-separator-outer">
                <div className="wt-separator bg-black" />
              </div>
            </div>
            {/* TITLE END */}
            {/* TESTIMONIAL 4 START ON BACKGROUND */}
            <div className="section-content">
              <OwlCarousel
                className="owl-carousel testimonial-home"
                {...options}
              >
                {members.map((item, index) => (
                  <div className="item" key={index}>
                    <div className="testimonial-6">
                      <div className="testimonial-pic-block">
                        <div className="testimonial-pic">
                          <img
                            src={item.image}
                            alt=""
                            width={132}
                            height={132}
                          />
                        </div>
                      </div>
                      <div className="testimonial-text clearfix bg-white">
                        <div className="testimonial-detail clearfix">
                          <strong className="testimonial-name">
                            {item.fullname}
                          </strong>
                          <span className="testimonial-position p-t0">
                            {item.position}
                          </span>
                        </div>
                        <div className="testimonial-paragraph text-black p-t15">
                          <span className="fa fa-quote-left" />
                          <p dir="auto">{item.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="hilite-title p-lr20 m-tb20 text-right text-uppercase bdr-gray bdr-right">
            <strong>Client</strong>
            <span className="text-black">Says</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonials;
