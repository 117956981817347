import { useState, useEffect } from 'react';

export const useInView = elementRef => {
  const [inView, setInView] = useState(false);

  const isInView = element => {
    const bounding = element.getBoundingClientRect();
    return (
      bounding.bottom >= bounding.height / 2 &&
      bounding.bottom <= window.innerHeight + bounding.height / 2
    );
  };

  useEffect(() => {
    const myElement = elementRef.current;

    const inViewOnMount = isInView(myElement);
    setTimeout(() => {
      if (isInView(myElement) === inViewOnMount) setInView(inViewOnMount);
    }, 200);

    const handleScroll = () => {
      if (myElement) {
        const currentIsInView = isInView(myElement);
        setTimeout(() => {
          if (isInView(myElement) === currentIsInView)
            setInView(currentIsInView);
        }, 200);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [inView, elementRef]);

  return inView;
};
