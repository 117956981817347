import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import AboutUs from './pages/AboutUs';
import Header from './components/Header/Header';
import WorkMasonary from './pages/WorkMasonary';
import PostGallery from './pages/PostGallery';
import ContactUs from './pages/ContactUs';
import IMaket from './pages/IMaket/IMaket';
import Career from './pages/Career/Career';

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [pathname]);
  return (
    <>
      <Header />
      <main>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/projects" element={<WorkMasonary />} />
          <Route path="/projects/:projectID" element={<PostGallery />} />
          <Route path="/imaket" element={<IMaket />} />
          <Route path="/career" element={<Career />} />
          <Route path="/contact-us" element={<ContactUs />} />
        </Routes>
      </main>
      <Footer />
    </>
  );
}

export default App;
