import React from 'react';
import { useTitle } from '../hooks';
import { useParams } from 'react-router-dom';
import { getProjectById } from '../utils/api';

import Banner from '../components/Banner';
import RelatedProjects from '../components/RelatedProjects';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const PostGallery = () => {
  let { projectID } = useParams();
  const project = getProjectById(projectID);

  useTitle(project.title);
  const options = {
    loop: true,
    margin: 30,
    autoplay: true,
    nav: true,
    dots: true,
    navText: [
      '<i class="fa fa-angle-left"></i>',
      '<i class="fa fa-angle-right"></i>'
    ],
    responsive: {
      0: {
        items: 1
      },
      480: {
        items: 1
      },
      767: {
        items: 1
      },
      1000: {
        items: 1
      }
    }
  };
  return (
    <>
      <div className="page-content ">
        <Banner
          title={project.title}
          pagename="Project Details"
          bgimage={project.images.banner}
        />

        {/* SECTION CONTENT START */}
        <div className="section-full p-tb90 square_shape1 square_shape3 tm-blog-single-wrap">
          <div className="container">
            <div className="max-mid-container">
              <div className="blog-post blog-lg date-style-1 text-black">
                <div className="wt-post-media">
                  {/*Fade slider*/}
                  <OwlCarousel
                    className="project-imgs-carousel owl-carousel owl-fade-slider-one owl-btn-vertical-center owl-dots-bottom-right"
                    {...options}
                    key={`prjccrs${project.id}`}
                  >
                    {project.images.imgs.map((item, index) => (
                      <div className="item" key={`projimg${index}`}>
                        <div className="aon-thum-bx">
                          <img src={item} alt="" />
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>

                  {/*fade slider END*/}
                </div>
                <div className="wt-post-info p-t30 bg-white">
                  <div className="wt-post-title ">
                    <h2 className="post-title">
                      <span className="text-black font-20 letter-spacing-1 font-weight-600">
                        {project.title}
                      </span>
                    </h2>
                  </div>
                  <div className="wt-post-text">
                    <p>{project.description.par1}</p>
                    <p>{project.description.par2}</p>
                  </div>
                </div>
              </div>

              <div className="wt-divider divider-3px bg-gray-dark">
                <i className="icon-dot c-square" />
              </div>
              <RelatedProjects project={project} key={`prjrltd${project.id}`} />
            </div>
          </div>
        </div>
        {/* SECTION CONTENT END */}
      </div>
    </>
  );
};

export default PostGallery;
