import React, { useRef, useState, useEffect } from 'react';
import {
  playIcon,
  pauseIcon,
  mutedIcon,
  unMutedIcon,
  fullscreenIcon
} from '../../assets';
import { useInView } from '../../hooks';

import './videoPlayer.css';

const VideoPlayer = ({
  source,
  className,
  muted,
  autoPlay,
  allowFullScreen,
  ...opts
}) => {
  const [playing, setPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(muted);
  const sVid = useRef();
  const videoContainer = useRef();
  const loaded = useRef(false);
  const inView = useInView(sVid);

  useEffect(() => {
    if (!inView) setPlaying(false);
    if (inView && !loaded.current) {
      setPlaying(autoPlay);
      loaded.current = true;
    }
  }, [inView]);

  useEffect(() => {
    if (playing) sVid.current.play();
    else sVid.current.pause();
  }, [playing]);

  const handleFullScreen = () => {
    const fullscreenElement =
      document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement ||
      document.msFullscreenElement;
    if (fullscreenElement) {
      exitFullscreen();
    } else {
      launchIntoFullscreen(videoContainer.current);
    }
  };

  function launchIntoFullscreen(element) {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
  }

  function exitFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  }

  return (
    <div ref={videoContainer} className={`video-player ${className}`}>
      <video
        {...opts}
        muted={isMuted}
        ref={sVid}
        width="100%"
        height="100%"
        onEnded={() => setPlaying(false)}
      >
        <source src={source} type="video/mp4" />
      </video>
      <div className={`video-controller ${playing ? 'isplaying' : ''}`}>
        <button
          type="button"
          className="mute-btn"
          onClick={() => setIsMuted(!isMuted)}
        >
          <img
            src={unMutedIcon}
            alt="sound controller"
            style={{ display: `${isMuted ? 'none' : ''}` }}
          />
          <img
            src={mutedIcon}
            alt="sound controller"
            style={{ display: `${isMuted ? '' : 'none'}` }}
          />
        </button>
        <button
          type="button"
          className="play-btn"
          onClick={() => setPlaying(!playing)}
        >
          <img
            src={playIcon}
            alt="play"
            loading="eager"
            style={{ display: `${playing ? 'none' : ''}` }}
          />
          <img
            src={pauseIcon}
            alt="play"
            loading="eager"
            style={{ display: `${playing ? '' : 'none'}` }}
          />
        </button>

        {allowFullScreen && (
          <button type="button" onClick={handleFullScreen}>
            <img src={fullscreenIcon} alt="fullscreen" loading="eager" />
          </button>
        )}
      </div>
    </div>
  );
};

export default VideoPlayer;
