import React from 'react';
import { Link } from 'react-router-dom';
import { aboutSummary, aboutSummaryMobile } from '../../assets';

import './aboutSummary.css';

const AboutSummary = () => {
  return (
    <section className="about-section">
      <div className="sample-container">
        <picture>
          <source srcSet={aboutSummary} media="(min-width: 720px)" />
          <img src={aboutSummaryMobile} alt="" />
        </picture>
      </div>
      <h2 className="about-heading">
        Proudly Introducing <span className="bold gold">Archrete</span>
      </h2>

      <p className="about-pars par1">
        Archrete is one of The world's leading company in the field of design
        and supervision of all types of architectural buildings. Since our
        inception in 2015, by Mohamed samir , Mohamed Tmam and Mostafa afifi
      </p>
      <p className="about-pars par2">
        Archrete is one of The world's leading company in the field of design
        and supervision of all types of architectural buildings.
      </p>
      <Link className="link-btn" to="/about">
        About Us
      </Link>
    </section>
  );
};

export default AboutSummary;
