import React, { useRef, useState, useEffect } from 'react';
import { useInView } from '../../hooks';
import './ourClients.css';
import { ourClientsLogo } from '../../assets';
import * as clientLogos from '../../assets/clients';

const OurClients = () => {
  const [expanded, setExpanded] = useState(false);
  const container = useRef(null);
  const inView = useInView(container);

  useEffect(() => {
    const element = container.current;
    if (element && !expanded) {
      let timeoutID;
      if (inView) timeoutID = setTimeout(() => handleScroll(), 2000);

      const handler = () => {
        clearTimeout(timeoutID);
        if (inView) {
          if (element.scrollLeft + element.clientWidth >= element.scrollWidth)
            timeoutID = setTimeout(
              () =>
                element.scrollTo({
                  left: 0,
                  behavior: 'smooth'
                }),
              2000
            );
          else timeoutID = setTimeout(() => handleScroll(), 2000);
        }
      };
      element.addEventListener('scroll', handler);

      return () => {
        clearTimeout(timeoutID);
        element.removeEventListener('scroll', handler);
      };
    }
  }, [inView, expanded]);

  const handleExpand = () => {
    setExpanded(prev => !prev);
    container.current.scrollIntoView(true);
  };

  const handleScroll = (left = false) => {
    const sContainer = container.current;
    const width = sContainer.clientWidth;

    sContainer.scrollBy({ left: left ? -1 * width : width });
  };
  return (
    <section className={`our-clients-section ${expanded ? 'expanded' : ''}`}>
      <div
        className="our-clients-logo"
        style={{ backgroundImage: `url('${ourClientsLogo}')` }}
      >
        <h2>our clients</h2>
      </div>
      <div className="clients-list">
        {!expanded && (
          <div className="our-clients-controller">
            <button
              type="button"
              onClick={() => handleScroll(true)}
            >{`<`}</button>
            <button
              type="button"
              onClick={() => handleScroll(false)}
            >{`>`}</button>
          </div>
        )}
        <div ref={container} className="clients-container">
          {Object.keys(clientLogos).map((key, i) => {
            return (
              <div className="client-logo" key={i + key}>
                <img src={clientLogos[key]} alt="logo" />
              </div>
            );
          })}
        </div>
      </div>
      <button className="expand-btn" onClick={handleExpand}>
        <i className={`fa fa-chevron-${expanded ? 'up' : 'down'}`}></i>
      </button>
    </section>
  );
};

export default OurClients;
