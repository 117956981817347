import React, { useState } from 'react';
import { useTitle } from '../../hooks';
import './career.css';
import { footerLogo } from '../../assets';

import Banner from '../../components/Banner';
import GoogleForm from '../../components/GoogleForm';

const bnrimg = require('../../images/banner/about.webp');

const formID = '1FAIpQLSfLpEh1_ganTC4AJiqYlP57sbxvGDkpNea2p3sAaKEQnlFwtg';

const Career = () => {
  useTitle('Career');

  const [inputs, setInputs] = useState({
    name: { id: 2005620554, value: '' },
    email: { id: 1045781291, value: '' },
    phone: { id: 1166974658, value: '' },
    jobTitle: { id: 1696049743, value: '' },
    notes: { id: 839337160, value: '' },
    cvLink: { id: 1644821350, value: '' }
  });

  const handleChange = e => {
    const { value, name } = e.target;
    setInputs({ ...inputs, [name]: { ...inputs[name], value } });
  };

  const clearInputs = () => {
    setInputs({
      name: { id: 2005620554, value: '' },
      email: { id: 1045781291, value: '' },
      phone: { id: 1166974658, value: '' },
      jobTitle: { id: 1696049743, value: '' },
      notes: { id: 839337160, value: '' },
      cvLink: { id: 1644821350, value: '' }
    });
  };

  return (
    <>
      <Banner bgimage={bnrimg} />
      <div className="page-container">
        <div className="bg-container"></div>
        <div className="career-page">
          <GoogleForm
            formID={formID}
            className="career-form"
            inputs={inputs}
            clearInputs={clearInputs}
          >
            <input
              className="input-field"
              type="text"
              name="name"
              value={inputs.name.value}
              onChange={handleChange}
              placeholder="Your Name"
              required
            />
            <input
              className="input-field"
              type="email"
              name="email"
              value={inputs.email.value}
              onChange={handleChange}
              placeholder="Your Email"
              required
            />
            <input
              className="input-field"
              type="phone"
              name="phone"
              value={inputs.phone.value}
              onChange={handleChange}
              placeholder="Your Phone"
              required
            />
            <input
              className="input-field"
              type="text"
              name="jobTitle"
              value={inputs.jobTitle.value}
              onChange={handleChange}
              placeholder="Job Title"
              required
            />
            <textarea
              className="input-field"
              type="text"
              name="notes"
              value={inputs.notes.value}
              onChange={handleChange}
              placeholder="Your Notes"
            />
            <input
              className="input-field"
              type="text"
              name="cvLink"
              value={inputs.cvLink.value}
              onChange={handleChange}
              placeholder="Link To Your C.V"
              required
            />
            <button type="submit" className="form-sbmt">
              Send Your Info
            </button>
          </GoogleForm>
          <img src={footerLogo} alt="" />
        </div>
      </div>
    </>
  );
};

export default Career;
